<template>
  <newBluePop v-if="successPopupShow" @close="closePopupB"/>
  <whitePopup v-if="showpopup" @close="closePopup" />
  <div class="container">
    <div class="form-title">
      Claim Report
    </div>
    <div class="form-subtitle">
      If you have received a defective item and would like to request a replacement, please use this form to report any
      defects in your order. Our team will review the submission and process your request as quickly as possible. <br><br>

      Your satisfaction is our priority, and we're here to ensure your experience is hassle-free 👌🏻
    </div>
    <div class="form-section">
      <form>
        <div class="field-in">
          <input type="text" v-model="name" @click="this.nameError = false" :class="{ fields: true, error: nameError }"
        class="form-input" required>
          <label class="form-label" v-if="!nameError">Your Name</label>
          <label class="form-label red" v-if="nameError">Your Name</label>
          <span class="input-error" v-if="nameError">
            <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M15.9993 29.3333C23.3631 29.3333 29.3327 23.3638 29.3327 16C29.3327 8.63616 23.3631 2.66663 15.9993 2.66663C8.63555 2.66663 2.66602 8.63616 2.66602 16C2.66602 23.3638 8.63555 29.3333 15.9993 29.3333Z"
                stroke="#F93232" stroke-width="1.33333" stroke-linecap="round" stroke-linejoin="round" />
              <path d="M20 12L12 20" stroke="#F93232" stroke-width="1.33333" stroke-linecap="round"
                stroke-linejoin="round" />
              <path d="M12 12L20 20" stroke="#F93232" stroke-width="1.33333" stroke-linecap="round"
                stroke-linejoin="round" />
            </svg>
            Please enter your name  
          </span>
        </div>

        <div class="field-in">
          <input type="name" v-model="orderNumber" @click="this.orderNumberError = false" :class="{ fields: true, error: orderNumberError }"
          class="form-input" required>
          <label class="form-label" v-if="!orderNumberError">Order number</label>
          <label class="form-label red" v-if="orderNumberError">Order number</label>
          <span class="input-error" v-if="orderNumberError">
            <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M15.9993 29.3333C23.3631 29.3333 29.3327 23.3638 29.3327 16C29.3327 8.63616 23.3631 2.66663 15.9993 2.66663C8.63555 2.66663 2.66602 8.63616 2.66602 16C2.66602 23.3638 8.63555 29.3333 15.9993 29.3333Z"
                stroke="#F93232" stroke-width="1.33333" stroke-linecap="round" stroke-linejoin="round" />
              <path d="M20 12L12 20" stroke="#F93232" stroke-width="1.33333" stroke-linecap="round"
                stroke-linejoin="round" />
              <path d="M12 12L20 20" stroke="#F93232" stroke-width="1.33333" stroke-linecap="round"
                stroke-linejoin="round" />
            </svg>
            Please enter your order number
          </span>
        </div>

        <div class="field-in country">
          <span>

          


            <input v-model="selectedItem"  ref="myInput" @focus="countryError = false" :class="{ fields: false, error: countryError }"
              name="country" @click="showDropdown" @blur="hideDropdown" @input="filterOptions"
              placeholder="Select product" class="form-control  fields" readonly />
            <svg @click="showDropdown" class="country-arrow" width="32" height="32" viewBox="0 0 32 32" fill="none"
              xmlns="http://www.w3.org/2000/svg">
              <path
                d="M10.8263 12.3866L15.9996 17.5599L21.173 12.3866C21.693 11.8666 22.533 11.8666 23.053 12.3866C23.573 12.9066 23.573 13.7466 23.053 14.2666L16.933 20.3866C16.413 20.9066 15.573 20.9066 15.053 20.3866L8.93297 14.2666C8.41297 13.7466 8.41297 12.9066 8.93297 12.3866C9.45297 11.8799 10.3063 11.8666 10.8263 12.3866Z"
                fill="#264EAB" />
            </svg>
            <span class="input-error" v-if="countryError">
              <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M15.9993 29.3333C23.3631 29.3333 29.3327 23.3638 29.3327 16C29.3327 8.63616 23.3631 2.66663 15.9993 2.66663C8.63555 2.66663 2.66602 8.63616 2.66602 16C2.66602 23.3638 8.63555 29.3333 15.9993 29.3333Z"
                  stroke="#F93232" stroke-width="1.33333" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M20 12L12 20" stroke="#F93232" stroke-width="1.33333" stroke-linecap="round"
                  stroke-linejoin="round" />
                <path d="M12 12L20 20" stroke="#F93232" stroke-width="1.33333" stroke-linecap="round"
                  stroke-linejoin="round" />
              </svg>
              Don't forget to select the product
            </span>
          </span>

          <div v-if="isDropdownVisible" class="dropdown">
            <ul class="dropdown-menu" style="display: block;">
              <a href="#" @click.prevent v-for="option in filteredOptions" :key="option.value"
                @click="selectItem(option)">
                <li @click="selectItem(option)">
                  {{ option.label }}
                </li>
              </a>
            </ul>
          </div>
        </div>
        <div class="field-in radioBtn" v-if="selectedItem === 'The Coffee Bag'">
          <span>Choose size</span>
          <p>
            <input type="radio" value="250 gr" v-model="coffeeBagSize" id="test1" name="radio-group" checked>
            <label for="test1">250 gr</label>
          </p>
          <p>
            <input type="radio" value="500 gr" v-model="coffeeBagSize" id="test2" name="radio-group">
            <label for="test2">500 gr</label>
          </p>
          <p>
            <input type="radio" value="1000 gr" v-model="coffeeBagSize" id="test3" name="radio-group">
            <label for="test3">1000 gr</label>
          </p>
          <p>
            <input type="radio" value="Individual Size" v-model="coffeeBagSize" id="test4" name="radio-group">
            <label for="test4">Individual size</label>
          </p>
        </div>
        <div class="field-in radioBtn" v-if="selectedItem === 'The Drip Bag'">
          <span>Select material</span>
          <p>
            <input type="radio" value="Plastic" v-model="dripMaterial" id="test1" name="radio-group" checked>
            <label for="test1">Plastic</label>
          </p>
          <p>
            <input type="radio" value="Paper" v-model="dripMaterial" id="test2" name="radio-group">
            <label for="test2">Paper</label>
          </p>
        </div>
        <div class="field-in country date">
          <span>
            <input type="name" ref="dateInput" v-model="datePicker" @click="showDateDrop" :class="{ fields: true, error: dateError }"
            name="country" @focus="showDropdownDate" @blur="hideDateDropdown" @input="filterOptions"
          class="form-input form-control  fields" required>
          <label class="form-label" v-if="!dateError">Production date</label>
          <label class="form-label red" v-if="dateError">Production date</label>

           
            <svg class="country-arrow" width="32" height="32" viewBox="0 0 32 32" fill="none"
              xmlns="http://www.w3.org/2000/svg">
              <path
                d="M10.8263 12.3866L15.9996 17.5599L21.173 12.3866C21.693 11.8666 22.533 11.8666 23.053 12.3866C23.573 12.9066 23.573 13.7466 23.053 14.2666L16.933 20.3866C16.413 20.9066 15.573 20.9066 15.053 20.3866L8.93297 14.2666C8.41297 13.7466 8.41297 12.9066 8.93297 12.3866C9.45297 11.8799 10.3063 11.8666 10.8263 12.3866Z"
                fill="#264EAB" />
            </svg>
            <span class="input-error" v-if="dateError">
              <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M15.9993 29.3333C23.3631 29.3333 29.3327 23.3638 29.3327 16C29.3327 8.63616 23.3631 2.66663 15.9993 2.66663C8.63555 2.66663 2.66602 8.63616 2.66602 16C2.66602 23.3638 8.63555 29.3333 15.9993 29.3333Z"
                  stroke="#F93232" stroke-width="1.33333" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M20 12L12 20" stroke="#F93232" stroke-width="1.33333" stroke-linecap="round"
                  stroke-linejoin="round" />
                <path d="M12 12L20 20" stroke="#F93232" stroke-width="1.33333" stroke-linecap="round"
                  stroke-linejoin="round" />
              </svg>
              Please select the production date
            </span>
          </span>

          <div v-if="isDropdownDateVisible" class="dropdown">
            <ul class="dropdown-menu" style="display: block;">
              <div class="picker-group">
                <div class="date-selector" @click="triggerBlur">
                  <VueScrollPicker @click="isDropdownDateVisible = false" v-model="year" :options="optionsYear" />
                  <VueScrollPicker v-model="month" :options="optionsMonth" />
                  <VueScrollPicker v-model="day" :options="optionsDay" />
                </div>
              </div>
            </ul>
          </div>
        </div>
        <div class="field-in">
        </div>
        <div class="field-in">
          <svg class="help" @mouseover="showHelp = true" width="33" height="32" viewBox="0 0 33 32" fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_1315_10318)">
              <path
                d="M16.75 5C10.678 5 5.75 9.928 5.75 16C5.75 22.072 10.678 27 16.75 27C22.822 27 27.75 22.072 27.75 16C27.75 9.928 22.822 5 16.75 5ZM17.85 23.7H15.65V21.5H17.85V23.7ZM20.127 15.175L19.137 16.187C18.587 16.748 18.191 17.254 17.993 18.046C17.905 18.398 17.85 18.794 17.85 19.3H15.65V18.75C15.65 18.244 15.738 17.76 15.892 17.309C16.112 16.671 16.475 16.099 16.937 15.637L18.301 14.251C18.807 13.767 19.049 13.041 18.906 12.271C18.763 11.479 18.147 10.808 17.377 10.588C16.156 10.247 15.023 10.94 14.66 11.985C14.528 12.392 14.187 12.7 13.758 12.7H13.428C12.79 12.7 12.35 12.084 12.526 11.468C12.999 9.851 14.374 8.619 16.079 8.355C17.751 8.091 19.346 8.96 20.336 10.335C21.634 12.128 21.249 14.053 20.127 15.175Z"
                fill="#264EAB" />
            </g>
            <defs>
              <clipPath id="clip0_1315_10318">
                <rect width="32" height="32" fill="white" transform="translate(0.75)" />
              </clipPath>
            </defs>
          </svg>
          <div class="help-box" v-if="showHelp">
            <div class="close" @click="closeHelp">
              <svg width="33" height="32" viewBox="0 0 33 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M11.8211 11.5284C12.0815 11.268 12.5036 11.268 12.7639 11.5284L16.2925 15.057L19.8211 11.5284C20.0815 11.268 20.5036 11.268 20.7639 11.5284C21.0243 11.7887 21.0243 12.2109 20.7639 12.4712L17.2353 15.9998L20.7639 19.5284C21.0243 19.7887 21.0243 20.2109 20.7639 20.4712C20.5036 20.7316 20.0815 20.7316 19.8211 20.4712L16.2925 16.9426L12.7639 20.4712C12.5036 20.7316 12.0815 20.7316 11.8211 20.4712C11.5608 20.2109 11.5608 19.7887 11.8211 19.5284L15.3497 15.9998L11.8211 12.4712C11.5608 12.2109 11.5608 11.7887 11.8211 11.5284Z"
                  fill="#264EAB" />
              </svg>
            </div>

            <div class="help-box-container">

              <div class="hs-title">
                Defective quantity
              </div>
              <div class="hs-desc">
                It’s the number of defects occured in the product
              </div>
            </div>
          </div>

          <input type="text" name="Adress" @click="this.defectsError = false" v-model="defects" :class="{ fields: true, error: defectsError }"
          class="form-input" required>
          <label class="form-label" v-if="!defectsError">Defective quantity</label>
          <label class="form-label red" v-if="defectsError">Defective quantity</label>

          <span class="input-error" v-if="defectsError">
            <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M15.9993 29.3333C23.3631 29.3333 29.3327 23.3638 29.3327 16C29.3327 8.63616 23.3631 2.66663 15.9993 2.66663C8.63555 2.66663 2.66602 8.63616 2.66602 16C2.66602 23.3638 8.63555 29.3333 15.9993 29.3333Z"
                stroke="#F93232" stroke-width="1.33333" stroke-linecap="round" stroke-linejoin="round" />
              <path d="M20 12L12 20" stroke="#F93232" stroke-width="1.33333" stroke-linecap="round"
                stroke-linejoin="round" />
              <path d="M12 12L20 20" stroke="#F93232" stroke-width="1.33333" stroke-linecap="round"
                stroke-linejoin="round" />
            </svg>
            Please enter the number of defects
          </span>
        </div>
        <div class="field-in">
          <textarea type="name" @click="this.messageError = false" v-model="message" :class="{ fields: true, error: messageError }"
            class="form-input" required></textarea>
          <label class="form-label" v-if="!messageError">Description</label>
          <label class="form-label red" v-if="messageError">Description</label>
          <span class="input-error" v-if="messageError">
            <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M15.9993 29.3333C23.3631 29.3333 29.3327 23.3638 29.3327 16C29.3327 8.63616 23.3631 2.66663 15.9993 2.66663C8.63555 2.66663 2.66602 8.63616 2.66602 16C2.66602 23.3638 8.63555 29.3333 15.9993 29.3333Z"
                stroke="#F93232" stroke-width="1.33333" stroke-linecap="round" stroke-linejoin="round" />
              <path d="M20 12L12 20" stroke="#F93232" stroke-width="1.33333" stroke-linecap="round"
                stroke-linejoin="round" />
              <path d="M12 12L20 20" stroke="#F93232" stroke-width="1.33333" stroke-linecap="round"
                stroke-linejoin="round" />
            </svg>
            Please provide a description of what exactly happened
          </span>
        </div>
        <div class="link-text">
          Paste a link to cloud storage to add photo or video evidence
        </div>
        <div class="popup-text" >
          👉 <span class="link-text" @click="showPop">
            What is cloud storage and how to use it?
          </span> 
        </div>


        <div class="field-in">
          <input type="name" v-model="orderNumber" @click="this.orderNumberError = false" :class="{ fields: true }"
          class="form-input" required>
          <label class="form-label" >Link to cloud storage</label>
        </div>
        <div class="checkbox-input" :class="{ 'error-checkbox': checkboxError }">
          <input type="checkbox" v-model="checkbox" class="custom-checkbox custom" id="happy" name="agree" value="yes">
          <label :for="checkboxError ? 'happy' : 'happy'" :class="{ 'error-label': checkboxError }">
            I consent to OyePackaging storing my submitted information so they can respond to my inquiry
          </label>
        </div>
        <button type="button" @click="createLead">SUBMIT</button>
      </form>
    </div>
  </div>
  <div>



  </div>
</template>
<script>
import { VueScrollPicker } from 'vue-scroll-picker'
import whitePopup from '@/components/whitePopup.vue'
import axios from 'axios';
import newBluePop from '@/components/newBluePop.vue'


export default {
  components: {
    VueScrollPicker, // export VueScrollPicker is component
    whitePopup,
    newBluePop
  },
  
  data() {

    return {
      coffeeBagSize: '',
      name: '',
      email: '',
      linkInput: '',
      linkInputError: false,
      message: '',
      country: '',
      year: '',
      day: '',
      defects: '',
      month: '',
      countryError: false,
      checkbox: false,
      nameError: false,
      showpopup: false,
      emailError: false,
      messageError: false,
      coffeeSize: '',
      dripMaterial: '',
      defectsError: false,
      orderNumber: '',
      orderNumberError: false,
      elements: [],
      phoneBlock: [],
      isSending: false, // Переменная для отображения статуса отправки
      checkboxError: false,
      showHelp: false,
      successPopupShow: false,
      dateError: false,
      isDropdownDateVisible: '',
      selectedItem: '',
      options: [
        { label: 'The Coffee Bag', value: 'Coffee' },
        { label: 'The Drip Bag', value: 'Drip' },
      ],
      optionsYear: [
        "2001", "2002", "2003", "2004", "2005", "2006", "2007", "2008", "2009", "2010", "2011", "2012", "2013", "2014", "2015", "2016", "2017", "2018", "2019", "2020", "2021", "2022", "2023"
      ],
      optionsMonth: [
        "January", "February", "March", "April",
        "May", "June", "July", "August",
        "September", "October", "November", "December"
      ],
      optionsDay: ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12", "13", "14", "15", "16", "17", "18", "19", "20", "21", "22", "23", "24", "25", "26", "27", "28", "29", "30", "31"],
      apiUrl: 'https://rasimmatsko0.kommo.com/api/v4/leads',
      integrationKey: '579eb8ec-d927-4f7b-aa00-44c88b906cf9',
      accessToken: 'YyfPAG5rIgAQafX4NoeFuahUNaqnQ2Jt2LY4nfVtGOENQvFZpcX09O4S9WX6ARPs',
      isDropdownVisible: false,
      filteredOptions: []
    };
  },
  mounted() {
    document.addEventListener('click', this.handleOutsideClick);
  },
  beforeUnmount() {
    document.removeEventListener('click', this.handleOutsideClick);
  },
  methods: {
    handleOutsideClick(event) {
      if (!this.$el.contains(event.target)) {
        this.showHelp = false;
      }
    },
    closeHelp() {
      this.showHelp = false;
    },
    createLead() {
      this.nameError = !this.name;
      this.checkboxError = !this.checkbox;
      this.countryError = !this.selectedItem;
      this.PhoneError = !this.phoneNumber;
      this.PostcodeError = !this.Postcode;
      this.dateError = !this.datePicker;
      this.defectsError = !this.defects;
      this.messageError = !this.message;
      this.linkInputError = !this.linkInput;
      this.orderNumberError = !this.orderNumber;


      if (this.selectedItem == 'The Coffee Bag') {
        this.selectedItem = this.selectedItem + ' ' + this.coffeeBagSize
      } else if (this.selectedItem == 'The Drip Bag') {
        this.selectedItem = this.selectedItem + ' ' + this.dripMaterial
      }
      console.log(this.selectedItem, this.coffeeBagSize)

      if (!this.nameError && !this.orderNumberError && !this.messageError && !this.defectsError && !this.dateError && !this.countryError) {
        const leadData = {
          name: this.name,
          orderNumber: this.orderNumber,
          link: this.linkInput,
          product: this.selectedItem,
          message: this.message,
          defects: this.defects,
          date: this.datePicker,
        };
        console.log('Lead Data:', JSON.stringify(leadData),)
        this.successPopupShow = true;
        console.log('method is called')
        fetch('api/sendReportData.php', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(leadData),
        })
          .then(response => {
            if (response.ok) {
              return response.json();
            } else {
              throw new Error('Failed to create lead.');
            }
          })
          .then(data => {
            this.successPopupShow = true;
            console.log('Lead created successfully:', data);
          })
          .catch(error => {
            console.error('Error creating lead:', error);
          });
      }

    },
    handleBlur() {
      console.log('Input потерял фокус');
    },
    triggerBlur() {
      // Получаем ссылку на input используя ref
      const input = this.$refs.dateInput;
      
      // Вызываем метод blur на input
      input.blur();
    },

    getAT() { // Get OAuth2
      console.log('Method is called')
      axios.get('api/getAccessToken.php')
        .then(response => {
          console.log('Access Token:', response.data);
        })
        .catch(error => {
          console.error('Error:', error);
        });
    },

    showDateDrop () {
      this.filteredOptions = this.options;

if (this.isDropdownDateVisible) {
  this.isDropdownDateVisible = false
} else {
  this.isDropdownDateVisible = true
}
    },
    validatePhone() {
      this.PhoneError = !this.phoneNumber;
      // Другая логика по обработке ввода телефона
    },
    showPop() {
      this.showpopup = true
    },
    closePopup() {
      this.showpopup = false;
    },
    closeLayoutPopup() {
      this.successPopupShow = false
    },
    hideDateDropdown() {
      this.datePicker = `${this.day} ${this.month} ${this.year}`
      this.isDropdownDateVisible = false
    },
    closePopupB() {
      this.successPopupShow = false;
    },

    selectItem(item) {
      console.log('selectItem called')
      this.selectedItem = item.label;
      this.isDropdownVisible = false;
      console.log(this.selectedItem); // Выводим выбранную страну в консоль
    },
    showDropdown() {
      this.isDropdownVisible ? this.isDropdownVisible = false : this.isDropdownVisible = true
      this.filteredOptions = this.options;
    },



    focusInput() {
      this.$refs.myInput.focus();
    },
    hideDropdown() {
      setTimeout(() => {
        this.isDropdownVisible = false;
      }, 200000);
    },
    filterOptions() {
      this.filteredOptions = this.options.filter(option =>
        option.label.toLowerCase().includes(this.selectedItem.toLowerCase())
      );
    },
    onSelect({ name, iso2, dialCode }) {
      console.log(name, iso2, dialCode);
    },
  },


}


</script>
<style src="../assets/pickerStyle.css"></style>
<style scoped>
.container {
  max-width: 1240px;
  margin: 0 auto;
  margin-top: 100px;
}

.form-title {
  color: #264EAB;
  font-size: 80px;
  font-family: 'RobotoMono-R', sans-serif;
  margin-bottom: 50px;

}

.form-subtitle {
  max-width: 1220px;
  color: #264EAB;
  font-family: 'RobotoMono-L', sans-serif;
  margin-bottom: 38px;
  text-transform: uppercase;

  color: var(--Main-Blue, #264EAB);

  /* Desktop/BODY 2 */
  font-size: 24px;
}

.form-section {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.popup-text .link-text {
  color: var(--Main-Blue, #264EAB);
  font-family: 'RobotoMono-R', sans-serif;
  font-size: 16px;
  text-decoration: underline;
  margin-bottom: 21px;

}

.popup-text:hover {
  cursor: pointer;
}


form .fields,
.vue-tel-input,
.country-input.fields input,
.fields input#phone {
  border-radius: 10px;
  background: var(--bg-2, #F5F5F5);
  width: 750px;
  height: 67px;
  outline: 0;
  border: 0;
  padding-left: 40px;
  margin-bottom: 20px;
}



.fields {
  margin: 20px 0;
}


.date-selector {
  display: flex;
}

.link-text {
  color: var(--Main-Blue, #264EAB);

  font-family: 'RobotoMono-R', sans-serif;
  font-size: 16px;
  margin-bottom: 21px;
  margin-top: 50px;
}


textarea::-webkit-scrollbar {
  width: 0px;
  height: 0px;
  }

  textarea {
    resize: none;
    height: 200px !important;
    padding-bottom: 20px !important;
  }

#phone {
  margin-bottom: 20px;
  padding-left: 40px;
  margin: 20px 0 !important;
  padding-left: 180px;
}

.iti__flag-container {
  left: 0px;
  width: 154px !important;

}



.popup-bg {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 999;
}

.fields.error

.popup-container {
  padding: 91px 233px 102px 153px;
  max-width: 1427px;
  background-color: #264EAB;
  font-size: 40px;
  font-family: 'RobotoMono-L', sans-serif;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
}

.date .form-input:focus~.form-label, .date.form-input:not(:focus):valid~.form-label {
  top: -33px;
}

.form-input:focus~.form-label::before, .form-label.red::before {
  top: -2px;
}

.date .form-input:not(:focus):valid~.form-label {
  top: -30px;
}

.date .form-input:focus~.form-label::before, .date .form-label.red::before {
  top: 3px;
}

.date .form-label {
  top: 3px
}

.popup-bg #close {
  position: absolute;
  right: 40px;
  top: 40px;
}

.p-text {
  max-width: 737px;
}

.p-title{
  font-family: 'RobotoMono-R', sans-serif;
}


@media screen and (max-width: 1490px) {
  .popup-container {
      padding: 90px 80px;
  }

  .p-image {
      max-width: 250px;
  }

  .popup-container {
      font-size: 32px
  }

  .p-text {
      max-width: 560px;
  }

  .p-image svg {
      width: 100%;
  }
}

@media screen and (max-width: 1045px) {
  .popup-container {
      font-size: 24px;
  }

  .p-text {
      max-width: 450px;
  }

  .p-image {
      max-width: 200px;
  }

  .popup-container {
      padding: 50px 70px;
  }
}

@media screen and (max-width: 830px) {
  .p-text {
      max-width: 350px;
  }

  .popup-container {
      font-size: 20px;
  }

  .p-image {
      max-width: 150px;
  }
}


@media screen and (max-width: 670px) {
  .p-image {
      display: none;
  }

  .popup-bg #close {
      top: 20px;
      right: 20px;
  }

  .popup-container {
      padding: 112px 44px 100px 65px;
  }
}

@media screen and (max-width: 475px) {
  .popup-container {
      padding: 30px;
      padding-top: 82px;
  }

  .p-text {
      max-width: 277px;
  }


  .popup-bg #close {
      top: 0;
      right: 0;
  }

  .p-title {
      font-size: 40px;
      margin-bottom: 25px;

  }


}


intl-tel-input {
  --iti-flag-size: 20px;
  --iti-flag-height: 20px;
}

input.vti__input {
  background: var(--bg-2, #F5F5F5) !important;
}

.vue-tel-input:focus-within {
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  border: 0 !important;
}

.vti__dropdown {
  margin-left: -5px !important;
}

.card {
  border-radius: 10px;
  background: var(--bg-2, #F5F5F5);
  width: 750px;
  height: 67px;
  outline: 0;
  border: 0;
  padding-left: 40px;
  margin-bottom: 20px;
}

[type="radio"]:checked,
[type="radio"]:not(:checked) {
  position: absolute;
  left: -9999px;
}

[type="radio"]:checked+label,
[type="radio"]:not(:checked)+label {
  position: relative;
  padding-left: 28px;
  cursor: pointer;
  line-height: 20px;
  display: inline-block;
  color: #666;

  color: var(--Main-Blue, #264EAB);

  font-size: 16px;
  line-height: normal;
}

[type="radio"]:checked+label:before,
[type="radio"]:not(:checked)+label:before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 18px;
  height: 18px;
  border: 1px solid #ddd;
  border-radius: 100%;
  background: #fff;
}

[type="radio"]:checked+label:before {
  border: 1px solid #264EAB;
}

[type="radio"]:checked+label:after,
[type="radio"]:not(:checked)+label:after {
  content: '';
  width: 12px;
  height: 12px;
  background: var(--Main-Blue, #264EAB);
  position: absolute;
  top: 3px;
  left: 3px;
  border-radius: 100%;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

[type="radio"]:not(:checked)+label:after {
  opacity: 0;
  -webkit-transform: scale(0);
  transform: scale(0);
}

[type="radio"]:checked+label:after {
  opacity: 1;
  -webkit-transform: scale(1);
  transform: scale(1);
}

.country.date .dropdown-menu {
  padding-right: 0;
  max-width: 320px;
  font-size: 16px;
}

.country.date input {
  max-width: 370px;
  margin-bottom: 0;
}

.radioBtn label {
  color: var(--Main-Blue, #264EAB);

  font-size: 16px;
}

.radioBtn p {
  margin: 10px 0;
}

#lines {
  width: 100%;
  display: flex !important;
  align-items: center;
  justify-content: space-between;
}

.p-dropdown span {
  margin-right: 10px;
}

.country {
  width: 750px;
  color: var(--main-blue, #264EAB);
  font-family: 'RobotoMono-L', sans-serif;
  font-size: 24px;
}

ul li {
  width: 750px;
}



.p-dropdown-filter.p-inputtext.p-component {
  display: none;
}


.custom-checkbox {
  position: absolute;
  z-index: -1;
  opacity: 0;
  max-width: 600px;
}


.custom-checkbox+label {
  display: inline-flex;
  align-items: center;
  user-select: none;
  font-size: 16px;
  font-family: 'RobotoMono-R', sans-serif;
  color: #264EAB;
  max-width: 485px;
  display: flex;
  align-items: flex-start;
  line-height: 20px;
  margin-bottom: 45px;
}


.custom-checkbox+label::before {
  content: '';
  display: inline-block;
  width: 1em;
  height: 1em;
  flex-shrink: 0;
  flex-grow: 0;
  border: 1px solid #264EAB;
  margin-right: 0.5em;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 50% 50%;
  max-width: 600px;
}


.custom-checkbox:checked+label::before {
  content: '•';
  font-size: 40px;
  width: 16px;
  height: 16px;
  border-color: #0b76ef;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 0px;
  margin-right: 8px;
}


form button {
  border: 4px solid var(--button-blue, #264EAB);
  color: var(--button-blue, #264EAB);
  font-family: 'RobotoMono-M', sans-serif;
  width: 241px;
  height: 54px;
  background-color: #fff;
  font-size: 18px;
  transition: 0.3s;
  margin-bottom: 100px;
}

form button:hover {
  background-color: #264EAB;
  color: #fff;
  cursor: pointer;
}


.checkbox-input {
  max-width: 600px;
}

.contact-info {
  max-width: 349px;
}

.adress-section,
.contact-schoose {
  font-size: 24px;
  font-family: 'RobotoMono-L', sans-serif;
  color: #264EAB;
  margin-bottom: 38px;
}


form {
  max-width: 750px;
}

.country {
  position: relative;
}

.country .input-error {
  position: absolute;
  bottom: -54px;
  font-size: 16px;
}


.adress-title,
.title,
.fs-title {
  font-size: 24px;
  font-family: 'RobootoMono-R', sans-serif;
}


.contact-schoose a,
.fs-title {
  color: #264EAB;
  text-decoration: none;
}

.input-error {
  display: flex;
  align-items: center;
  color: #FF0000;
  position: absolute;
  bottom: -16px;
}





.input-error svg {
  margin-right: 8px;
}

.field-in {
  max-width: 750px;
  width: 100%;
  display: inline-block;
  vertical-align: top;
  position: relative;
}

.help {
  position: absolute;
  right: 20px;
  top: 38px;
}

.help:hover {
  cursor: pointer;
}

.justify-content-center {
  width: 750px;
  padding-left: 40px;
  height: 69px;
}


#message {
  padding-bottom: 200px;
}

.fields::placeholder,
.form-control::placeholder,
.fields {
  color: var(--main-blue, #264EAB);
  /* Button Link/NORMAL */
  font-family: 'RobotoMono-L', sans-serif;
  font-size: 24px;
}

.fields.error,
textarea.errorInput {
  border: 1px solid #FF0000;
  background: #FFE7E7;
  transition: 0.3s;
}

textarea {
  padding: 20px 40px 200px 40px;
  margin-bottom: 41px;
}

.fields.error::placeholder,
textarea.errorInput::placeholder {
  color: #FF0000;
}



.checkbox-input.error-checkbox .custom-checkbox+label::before {
  border: 1px solid #FF0000;
}

.custom-checkbox+label {
  margin-top: 30px;

}

.checkbox-input.error-checkbox .custom-checkbox+label {
  color: #FF0000;

}

.form-control:hover {
  cursor: pointer;
}

.radioBtn {
  color: var(--Main-Blue, #264EAB);
  /* Button Link/NORMAL */
  font-family: 'RobotoMono-L', sans-serif;
  font-size: 24px;
}


.country span {
  position: relative;
}

.country-arrow {
  position: absolute;
  right: 20px;
  top: calc(50% - 16px);
  z-index: 999999;
}

.option-image {
  width: 20px;
  height: 20px;
  margin-right: 5px;
  vertical-align: middle;
}


.dropdown-menu {
  width: 100%;
  position: absolute;
  top: 60px;
  left: 0;
  box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.15);
  background: #F5F5F5;
  z-index: 999999999;
  padding-top: 20px;
  max-height: 354px;
  overflow-y: scroll;
  padding-right: 40px;
  padding-left: 0;
}

.dropdown-menu img {
  width: 46px;
  height: 27px;
}

.dropdown-menu li {
  list-style-type: none;
  padding: 20px 40px;
  padding-left: 0;
  padding-left: 20px;

}

.dropdown-menu ul li {
  padding-left: 0;
}

.dropdown-menu a:nth-of-type(5) li {
  border-bottom: 3px solid #F0F0F0 !important;

}

.dropdown-menu li:hover {
  background-color: #F0F0F0;
}

.dropdown-menu img {
  margin-right: 10px;
  margin-left: 20px;

}

.dropdown-menu a {
  text-decoration: none;
  font-size: 24px;
  color: #264EAB;
  width: 100% !important;

}

::marker {
  margin-right: 0;
}

.fields.error:last-child {
  margin-bottom: 40px !important;
}


@media screen and (max-width: 1280px) {
  .container {
    padding: 0 20px;
  }
}

@media screen and (max-width: 841px) {
  .form-title {
    font-size: 60px;
  }



  .form-subtitle {
    font-size: 28px;
  }
}

@media screen and (max-width: 803px) {

  form .fields,
  .vue-tel-input,
  .country-input.fields input,
  .fields input#phone {
    width: 100%;
  }

  .dropdown-menu li {
    font-size: 18px;
  }

  ul li {
    width: unset !important;
  }



  .field-in {
    width: 100%;
  }

  .fields::placeholder,
  .form-control::placeholder,
  .fields {
    font-size: 19px;
  }

  .form-section,
  .form-section form {
    width: 100%;
    max-width: unset;

  }
}

@media screen and (max-width: 630px) {
  .form-title {
    display: none;
  }

  .input-error {
    font-size: 12px;
    bottom: -10px;
  }

  .input-error svg {
    max-width: 20px;
    max-height: 20px;
  }

  .form-subtitle {
    font-size: 19px;
  }

  .help {
    top: 27px;
  }

  .container {
    margin-top: 50px;
  }


  form .fields {
    margin-top: 0;
    margin: 10px 0 !important;
  }

  .fields.error {
    margin-bottom: 25px !important;
  }

  .input-error {
    bottom: 2px;
    align-items: flex-start;
  }


  .date {
    margin-bottom: 10px !important;
  }

  .country {
    margin-top: 0;
  }

  .country .input-error {
    bottom: -65px;
    font-size: 12px;
  }

  .input-error:last-child {
    bottom: -5px;
  }

  .country.date {
    margin-bottom: 0 !important;
  }

  #phone {
    margin: 10px 0 !important;
    padding-left: 110px;
  }

  .checkbox-input {
    margin-top: 25px;
  }

  form button {
    width: 100%;
    margin-bottom: 50px;
  }

  .country .input-error {
    bottom: -40px;
  }
}


.iti__country-list {
  padding: 20px 0 !important;
}


.popup-bg {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 999;
}

.popup-container {
  padding: 91px 233px 102px 153px;
  max-width: 1427px;
  background-color: #264EAB;
  font-size: 40px;
  font-family: 'RobotoMono-L', sans-serif;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
}

#close {
  position: absolute;
  right: 40px;
  top: 40px;
}

.p-text {
  max-width: 737px;
}

.p-title {
  font-family: 'RobotoMono-R', sans-serif;
}


@media screen and (max-width: 1490px) {
  .popup-container {
    padding: 90px 80px;
  }

  .p-image {
    max-width: 250px;
  }

  .popup-container {
    font-size: 32px
  }

  .p-text {
    max-width: 560px;
  }

  .p-image svg {
    width: 100%;
  }
}

@media screen and (max-width: 1045px) {
  .popup-container {
    font-size: 24px;
  }

  .p-text {
    max-width: 450px;
  }

  .p-image {
    max-width: 200px;
  }

  .popup-container {
    padding: 50px 70px;
  }
}

@media screen and (max-width: 830px) {
  .p-text {
    max-width: 350px;
  }

  .popup-container {
    font-size: 20px;
  }

  .p-image {
    max-width: 150px;
  }

  
}

@media screen and (max-width: 805px) {
  .date .form-input:not(:focus):valid~.form-label {
    top: -26px;
  }
}

@media screen and (max-width: 670px) {
  .p-image {
    display: none;
  }

  #close {
    top: 20px;
    right: 20px;
  }

  .popup-container {
    padding: 112px 44px 100px 65px;
  }
}

@media screen and (max-width: 475px) {
  .popup-container {
    padding: 30px;
    padding-top: 82px;
  }

  .p-text {
    max-width: 277px;
  }


  #close {
    top: 0;
    right: 0;
  }

  .p-title {
    font-size: 40px;
    margin-bottom: 25px;

  }


}

.vue-scroll-picker-layer-top {
  background: #F5F5F5 !important;
}

.help-box {
  position: absolute;
  right: -32%;
  bottom: -70px;
  border-radius: 10px;
  background: var(--text-white, #FFF);
  box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.15);
  width: 287px;
  height: 127px;
  padding: 34px 62px 34px 20px;
  z-index: 999;
}

.help-box-container {
  position: relative;
}

.help-box .close {
  position: absolute;
  right: 10px;
  top: 10px;
}

.help-box .close:hover {
  cursor: pointer;
}

.hs-title {
  color: var(--fields-blue, #264EAB);

  font-size: 16px;
  margin-bottom: 5px;
}

.hs-desc {
  color: var(--fields-blue, #264EAB);
  font-size: 13px;
}

@media screen and (max-width: 1170px) {
  .form-subtitle {
    font-size: 22px;
  }
}

@media screen and (max-width: 1050px) {
  .help-box {
    right: 30px;
  }
}


@media screen and (max-width: 840px) {
  .form-subtitle {
    font-size: 20px;
  }


  .form-title {
    font-size: 50px;
  }
}

@media screen and (max-width: 720px) {
  .form-subtitle {
    font-size: 18px;
  }
}

@media screen and (max-width: 630px) {
  .form-title {
    display: flex;
    font-size: 40px;
  }


  .form-subtitle {
    font-size: 19px;
  }
}
</style>