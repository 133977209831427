<template>
    <router-link :to="path">
        <button>{{ text }}</button>
    </router-link>
</template>
  
<script>
export default {
    props: {
        path: String,
        text: String
    }
}
</script>

<style scoped>
button {
    color: var(--button-blue, #264EAB);
    font-family: 'RobotoMono-R';
    font-size: 18px;
    font-style: normal;
    text-transform: uppercase;
    padding: 10px 50px;
    border: 4px solid var(--Main-Blue, #264EAB);
    transition: 0.3s;
}

button:hover {
    cursor: pointer;
    background: #264EAB;
    color: #fff;
}


    @media screen and (max-width: 470px) {
        button {
            font-size: 17px;
            width: 100%;
        }

        
    }
</style>
  