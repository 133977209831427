<template>
    <router-link to="/quote" class="blue-btn">get a quote</router-link>
</template>
<script>
export default {
    
}
</script>
<style scoped>
 a {
    padding: 10px 57px;
    font-family: 'RobotoMono-M', sans-serif;
    font-size: 18px;
    outline: 0;
    text-decoration: none;
    text-transform: uppercase;
}



.blue-btn {
    background: #264EAB;
    color: #fff;
    border: 4px solid #264EAB;
    transition: 0.3s;
}

.blue-btn:hover {
    background-color: #fff;
    color: #264EAB;
}
</style>