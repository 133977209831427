<template>
    <div v-if="showPopup2" class="popup" @click="closePopup">
        <div class="popup-content">
            <div class="popup-title">
                Key Differences in ISO Certification
            </div>
            <div class="popup-text">
                <span class="popup-title">
                    Accreditation Bodies:
                </span>
                <ul>
                    <li><span class="bold"> EU:</span>
                        The European co-operation for Accreditation (EA) oversees national accreditation bodies like UKAS (UK), DAkkS (Germany), and COFRAC (France).
                    </li>
                    <li><span class="bold"> China:</span>
                        China's CNAS handles accreditation but may have differences in approach.
                    </li>
                </ul>
            </div>
            <div class="popup-text">
                <span class="popup-title">
                    Certification Bodies:
                </span>
                <div class="text">
                    Global certifiers like SGS, TÜV, and Intertek operate in both regions but adapt to local nuances.
                </div>
            </div>
            <div class="popup-text">
                <span class="popup-title">
                    Local Regulations and Standards:
                </span>
                <ul>
                    <li><span class="bold"> EU:</span> Adherence to EU standards is required, and some materials have specific regulations, e.g., plastics (<a href="https://op.europa.eu/en/web/eu-law-in-force/bibliographic-details/-/elif-publication/84665a0a-f9e5-4eae-aac5-af4fd4c8ac94">Regulation (EU) No. 10/2011</a>).
                    </li>
                    <li><span class="bold"> China:</span>Chinese standards (e.g., "GB") may differ from international norms, impacting ISO compliance.
                    </li>
                </ul>
            </div>
            <div class="popup-text">
                <span class="popup-title">
                    Cultural Differences:
                </span>
                <ul>
                    <li><span class="bold"> EU:</span>
                        Business practices are influenced by EU culture, history, and socioeconomic factors.
                    </li>
                    <li><span class="bold"> China:</span>
                        Unique Chinese business practices are influenced by their culture and history.
                    </li>
                </ul>
            </div>

            <div class="popup-text">
                <span class="popup-title">
                    Perception and Usage:
                </span>
                <div class="text">
                    ISO certification motivation differs; Europe prioritizes sustainability, while other regions may have distinct drivers.
                </div>
            </div>

           
          
            <div class="popup-close " @click="closePopup">
                <svg class="close" width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M9.165 9.13827C9.36026 8.94301 9.67685 8.94301 9.87211 9.13827L12.5186 11.7847L15.165 9.13827C15.3603 8.94301 15.6768 8.94301 15.8721 9.13827C16.0674 9.33353 16.0674 9.65011 15.8721 9.84537L13.2257 12.4918L15.8721 15.1383C16.0674 15.3335 16.0674 15.6501 15.8721 15.8454C15.6768 16.0406 15.3603 16.0406 15.165 15.8454L12.5186 13.1989L9.87211 15.8454C9.67685 16.0406 9.36026 16.0406 9.165 15.8454C8.96974 15.6501 8.96974 15.3335 9.165 15.1383L11.8114 12.4918L9.165 9.84537C8.96974 9.65011 8.96974 9.33353 9.165 9.13827Z" fill="#264EAB"/>
                    </svg>
                    
            </div>
            <div class="close-btn" @click="closePopup">
                close
            </div>
        </div>
    </div>
</template>
  
<script>
export default {
    props: {
        showPopup2: Boolean
    },
    methods: {
        closePopup() {
            if (event.target.classList.contains('popup') || event.target.classList.contains('close') || event.target.classList.contains('close-btn')) {
                this.$emit('close-popup');
            }
        }
    }
}
</script>
    .
<style scoped>
body {
    position: relative;
}

.popup {
    background: rgba(0, 0, 0, 0.2);
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 9999;
}

.popup-content {
    background: var(--bg-1, #FFF);
    box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.15);
    padding: 60px 40px;
    max-width: 1000px;
    position: relative;
    height: fit-content;
    overflow-y: scroll;
}

.popup-title {
    color: var(--button-blue, #264EAB);
    font-family: 'RobotoMono-B', sans-serif;
    font-size: 16px;
    line-height: normal;
    margin-bottom: 44px;
    
}

.close-btn {
    color: #C3C3C3;
font-family: 'RobotoMono-R', sans-serif;
font-size: 16px;
text-decoration-line: underline;
margin-top: 25px;
display: flex;
    justify-content: center;
    display: none;
}

ul li {
    color: var(--button-blue, #264EAB);
    font-family: 'RobotoMono-R', sans-serif;
    font-size: 16px;
    line-height: normal;
    margin-bottom: 10px;
}

li a {
    color: var(--button-blue-hover, #3771F8);
    font-size: 16px;
    text-decoration-line: underline;
}

li .bold {
    margin-right: 5px;
    font-family: 'RobotoMono-B', sans-serif;
}

.text {
    padding-left: 20px;
    color: var(--button-blue, #264EAB);
font-family: 'RobotoMono-R', sans-serif;
font-size: 16px;
line-height: normal;
margin-top: 18px;
margin-bottom: 18px;
}

.popup-close {
    position: absolute;
    top: 40px;
    right: 40px;
}

.popup-close:hover {
    cursor: pointer;
}



@media screen and (max-width: 1300px) {
    .popup-content {
        padding-left: 20px;
        padding-right: 20px;
        overflow-y: scroll;
        height: 100%;
    }
}


@media screen and (max-width: 680px) {
    .popup-content {
        padding-top: 80px;
    }

    .popup-title:first-child {
        max-width: 250px
    }

    .popup-content {
        padding: 80px 40px 60px 40px;
    }

    ul {
        padding-left: 50px;
    }

    .text {
        padding-left: 30px;
    }
}
@media screen and (max-width: 650px) {
    .close-btn {
        display: flex;
    }
}
@media screen and (max-width: 450px) {
    ul, text {
        max-width: 300px;
    }


}

.popup-close:hover {
    cursor:pointer
}
</style>
  