<template>
    <div class="container">
        <!-- <div class="about-title">
            <span class="bold-text">ÖYE Packaging</span> <br>
            Team of Coffee and Packaging Enthusiasts
        </div> -->
        <div class="welcome-title">
            Welcome to ÖYE Packaging!
        </div>
        <div class="welcome-desc">
            <span>
                <span class="reg-text">We are here </span> to help you get the best sustainable packaging and co-packing services designed
                exclusively for coffee roasters. 
            </span> <br><br>
            <span>
                <span class="reg-text">Our commitment</span> is to solve the everyday issues faced by coffee roasters, such as boring
                packaging choices, long deliveries and complexities of filling coffee in formats like drip bags and
                capsules.
            </span>
        </div>
        <div class="fest-section">
            <div class="double first">
                <div class="double-text">
                    <div class="year">2019</div>
                    <div class="description">
                        Our coffee journey started at the <span class="reg-text">Amsterdam Coffee Festival></span> ☕, where we fell in love with specialty coffee - an experience that changed our lives!
                    </div>
                </div>
                <div class="double-img">
                    <img  loading="lazy" src="../assets/img/coffeeFest.webp" alt="">
                </div>
            </div>
            <div class="single">
                With years of creating recyclable packaging and innovations for big brands, we decided to combine our collective experience and passion for specialty coffee to solve packaging and roasting culture challenges.
            </div>
            <div class="double">
                <div class="double-img">
                    <img  loading="lazy" src="../assets/img/about2.webp" alt="">
                </div>
                <div class="double-text">
                    <div class="year">2023</div>
                    <div class="description">
                       <span class="reg-text"> ÖYE Packaging</span> was born to offer highly innovative and recyclable coffee packaging and provide mind-blowing co-packaging services that will drive you crazy 🤯
                    </div>
                </div>
            </div>
            <div class="single">Our services are diverse, and this is just the beginning 😎</div>

        </div>
    </div>
    <div class="grey-section">
        <div class="grey-container">
            Stay tuned, keep exploring, and join us on this thrilling journey to revolutionize the world of coffee.
        </div>
    </div>
    <catCta/>
   
</template>
<script>

import catCta from '@/components/catCta.vue';
export default {
    components: {
        catCta
    }
}
</script>
<style scoped>
.container {
    max-width: 1240px;
    margin: 0 auto;
    margin-top: 100px;
}

.about-title, .welcome-title {
    color: #264EAB;
font-family: 'RobotoMono-L', sans-serif;
font-size: 80px;
margin-bottom: 68px;
}

span.bold-text {
    font-family: 'RobotoMono-B', sans-serif;
}

.about-title-img {
    margin-bottom: 50px;
}

.welcome-title {
    text-align: center;
    margin-bottom: 38px;
}

.welcome-desc {
    font-size: 32px;
    color: #264EAB;
    font-family: 'RobotoMono-L', sans-serif;
    margin-bottom: 50px;
}

span.reg-text {
    font-family: 'RobotoMono-R', sans-serif;
}

.double {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 50px;
}

.double-text{
    color: #264EAB;
font-family: 'RobotoMono-L', sans-serif;
font-size: 32px;
max-width: 633px;
}

.year{
    color: #264EAB;
font-family: 'RobotoMono-B', sans-serif;
font-size: 80px;
margin-bottom: 50px;
}

.single{
    color: #264EAB;
    font-size: 32px;
    margin-bottom: 50px;
}

.grey-section {
    background-color: #F5F5F5;
}

.grey-container {
    max-width: 1240px;
    margin: 0 auto;
    padding: 40px 0;
    font-size: 46px;
    color: #264EAB;
    font-family: 'RobotoMono-R', sans-serif;
}

@media screen and (max-width: 1270px) {
    .container {
        padding: 0 20px;
    }

    .grey-container {
        padding: 40px 20px;
    }


    .about-title-img {
        max-width: 100%;
    }

    .about-title-img img {
        width: 100%;
    }

    .double-text {
        max-width: 565px;
    }
}


@media screen and (max-width: 1180px) {
    .double-img {
        max-width: 450px;
    }

    .double-img img {
        width: 100%;
    }

    .double-text .description,  .single {
        font-size: 28px;
    }

    .double-text .year {
        font-size: 65px;
        margin-bottom: 30px;
    }



}

@media screen and (max-width: 1050px) {
    .about-title, .welcome-title {
        font-size: 70px;
    }

    .double-text .description{
        font-size: 22px;
        max-width: 475px;
    }

    .double-text .single {
        font-size: 22px;
    }

    .grey-container {
        font-size: 35px;
    }

    .welcome-desc {
        font-size: 27px;
    }

    
}

@media screen and (max-width: 980px) {
    .about-title, .welcome-title {
        font-size: 60px;
    }

    .double-text .description {
        font-size: 18px;
    }

    .double-text .year {
        font-size: 40px;
    }

    .double-img {
        max-width: 400px;
    }

    .grey-container {
        font-size: 28px;
    }

}

@media screen and (max-width: 940px) {
    .double-text .description {
        font-size: 16px;
        max-width: 455px;
    }

    .double-img {
        max-width: 380px;
    }

    .single {
        font-size: 17px;
    }

    .welcome-desc {
        font-size: 18px;
    }

    .grey-container {
        font-size: 24px;
    }
}

@media screen and (max-width: 880px) {
    .double{
        flex-direction: column;
    }

    .double.first .double-img {
        order: 1;
    }

    .double.first .double-text {
        order: 2;
    }

    .double-img {
        max-width: 700px;
        margin: 25px auto 18px auto;
    }

    .welcome-desc {
        margin-bottom: 0;
    }

    .double-text {
        max-width: unset;
    }

    .double-text .description {
        max-width: unset;
    }

    
}

@media screen and (max-width: 800px) {
    .about-title, .welcome-title {
        font-size: 50px;
    }
}


@media screen and (max-width: 680px) {
    .about-title, .welcome-title {
        font-size: 40px;
    }

    .welcome-title {
        text-align: left;
    }

    .container {
        margin-top: 50px;
    }

    .double-text .year, .double, .single {
        margin-bottom: 25px;
    }
}


@media screen and (max-width: 550px) {
    .grey-container {
        font-size: 19px;
    }

    .about-title, .welcome-title {
        font-size: 31px;
    }
    .about-title {
        margin-bottom: 38px;
    }

    .about-title-img {
        margin-bottom: 35px;
    }
}

@media screen and (max-width: 460px) {
    .about-title, .welcome-title {
        font-size: 25px;
    }

    .welcome-desc, .double-text .description, .single {
        font-size: 16px;
    }

    .double-text .year {
        font-size: 25px;
    }
}

</style>