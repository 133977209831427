<template>
    <div v-if="showMinimumTooltip" class="tooltip-container" @mouseover="cancelCloseTimer" @mouseout="startCloseTimer">
      <div class="tooltip-text">
        For printed bags or with custom designs
      </div>
      <div class="tooltip-close" @click="closeTooltip">
        <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 10 10" fill="none">
          <path d="M0.435497 0.303661C0.695846 0.0433111 1.11796 0.0433111 1.37831 0.303661L4.9069 3.83226L8.4355 0.303661C8.69585 0.0433111 9.11796 0.0433111 9.37831 0.303661C9.63866 0.56401 9.63866 0.98612 9.37831 1.24647L5.84971 4.77506L9.37831 8.30366C9.63866 8.56401 9.63866 8.98612 9.37831 9.24647C9.11796 9.50682 8.69585 9.50682 8.4355 9.24647L4.9069 5.71787L1.37831 9.24647C1.11796 9.50682 0.695846 9.50682 0.435497 9.24647C0.175147 8.98612 0.175147 8.56401 0.435497 8.30366L3.96409 4.77506L0.435497 1.24647C0.175147 0.98612 0.175147 0.56401 0.435497 0.303661Z" fill="#264EAB"/>
        </svg>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    props: {
      showMinimumTooltip: Boolean
    },
    data() {
      return {
        closeTimer: null
      };
    },
    methods: {
      closeTooltip() {
        this.cancelCloseTimer();
        this.$emit('close-MinimumTooltip');
      },
      startCloseTimer() {
        this.closeTimer = setTimeout(() => {
          this.closeTooltip();
        }, 1000);
      },
      cancelCloseTimer() {
        clearTimeout(this.closeTimer);
      }
    }
  }
  </script>
  
  <style scoped>
  .tooltip-container {
    border-radius: 10px;
    background: var(--fields-white, #FFF);
    box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.15);
    color: var(--fields-blue, #264EAB);
    font-family: 'RobotoMono-R', sans-serif;
    font-size: 16px;
    line-height: normal;
    padding: 20px;    
    position: absolute;
    width: 264px;
  }
  
  .tooltip-close {
    position: absolute;
    right: 10px;
    top: 10px;
  }
  .tooltip-close:hover {
    cursor: pointer;
  }
  </style>
  