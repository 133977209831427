<template>
    <router-link :to="path" class="transp-btn">{{ text }}</router-link>
</template>
<script>

export default {
    props: {
        path: {
            type: String,
            required: true
        },

        text: {
            type: String, 
            required: true
        }
    }    
}
</script>
<style scoped>
.transp-btn {
    padding: 10px 30px;
    color: #264EAB;
    font-family: 'RobotoMono-M', sans-serif;
    font-size: 18px;
    text-transform: uppercase;
    text-decoration: none;
    width: 241px !important;
    height: 54px ;
}

.transp-btn {
    border: 4px solid #264EAB;
    background-color: transparent;
    color: #264EAB;
    margin-right: 40px;
    transition: 0.3s;
}

.transp-btn:hover {
    background: #264EAB;
    color: #fff;
}


@media screen and (max-width: 730px) {
        .transp-btn {
            padding: 7px 25px;
        }
}   

</style>