<template>
    <div class="container">
        <div class="talk-title">
            Talk to us
        </div>
        <div class="talk-desc">
            On this page, you can easily book a call, select a date that suits you best, and delve into meaningful conversations. <br><br>

We're here to listen, collaborate, and help you explore ideas, so go ahead and let’s start discussing all the details!
        </div>
    </div>
    <iframe src="https://calendly.com/hello-oye-team"  width="100%" frameborder="0"></iframe>
</template>
<script>
export default {
    components: {
    }
}
</script>
<style scoped> .container {
     margin: 0 auto;
     max-width: 1240px;
    
    }

    .talk-title {
        color: var(--Main-Blue, #264EAB);
        font-family: 'RobotoMono-R', sans-serif ;
        font-size: 80px;
       margin-top: 100px;
       margin-bottom: 50px;
    }

    .talk-desc {
        color: var(--Main-Blue, #264EAB);
font-family: 'RobotoMono-L', sans-serif;
font-size: 32px;
margin-bottom: 50px;
    }

 iframe {
     min-height: 90vh;
     margin-bottom: 70px;
     
 }


 @media screen and (max-width: 1250px) {
    .container {
        padding-right: 20px;
        padding-left: 20px;
    }
 }

 @media screen and (max-width: 1100px) {
    .talk-title {
        font-size: 60px;
    }

    .talk-desc {
        font-size: 25px;
    }
 }

 @media screen and (max-width: 800px) {
    .container{
        font-size: 60px;
        margin: 50px 0;
    }
 }

 @media screen and (max-width: 768px) {
    .talk-title{
        font-size: 50px;
        margin-top: 50px;
    }

    .talk-desc {
        font-size: 22px;
    }
 }

 @media screen and (max-width: 600px) {
    .container {
        font-size: 36px;
    }

    .talk-title{
        font-size: 36px;
        margin-bottom: 50px;
    }

    .talk-desc{
        font-size: 25px;
    }
 }


</style>