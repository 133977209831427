<template>
    <div class="popup" @click="handleClick">
        <div class="popup-container">
            <div class="close" @click="closePopup">
                <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M9.39596 8.64632C9.59122 8.45106 9.9078 8.45106 10.1031 8.64632L12.7495 11.2928L15.396 8.64632C15.5912 8.45106 15.9078 8.45106 16.1031 8.64632C16.2983 8.84159 16.2983 9.15817 16.1031 9.35343L13.4566 11.9999L16.1031 14.6463C16.2983 14.8416 16.2983 15.1582 16.1031 15.3534C15.9078 15.5487 15.5912 15.5487 15.396 15.3534L12.7495 12.707L10.1031 15.3534C9.9078 15.5487 9.59122 15.5487 9.39596 15.3534C9.2007 15.1582 9.2007 14.8416 9.39596 14.6463L12.0424 11.9999L9.39596 9.35343C9.2007 9.15817 9.2007 8.84159 9.39596 8.64632Z"
                        fill="#264EAB" />
                </svg>

            </div>
            <div class="popup-title">
                What is cloud storage and how to use it?
            </div>
            <div class="popup-text">
                <span class="bold">Cloud storage</span> is like a virtual space on the internet where you can keep your
                files and data, so you can access them from different devices anytime you want. <br>
                Here are some of the most popular: <span class="bold">Google Drive, Dropbox, Microsoft OneDrive, iCloud (by
                    Apple)</span> , etc. We accept links from any storage. <br>
                The principle of operation of cloud services is similar and easy!
            </div>
            <div class="popup-title">
                Here's a simple guide:
            </div>
            <div class="popup-text wir">
                1. <span class="bold">Sign up:</span> Create an account on a cloud storage platform like <span
                    class="bold">Google Drive, Dropbox,</span> or others. <br><br>

                2. <span class="bold">Upload files:</span> Find the <span class="bold">"Upload"</span> button. Select photos
                or videos you want to store in the cloud, create a folder called <span class="bold">“for Oye
                    Packaging”</span> and upload the files in it. <br><br>

                3. <span class="bold">Share files with us:</span> Select <span class="bold">"Share"</span> or <span
                    class="bold">"Copy link"</span> option. Paste the link into the field below to send it to us. And that’s
                it! 🤩
            </div>
            <div class="subtext f">
                👉🏻 One more fast and simple option is to use the <a href="https://wetransfer.com/"
                    target="_blank">wetransfer.com</a> website that allows you to upload files up to 2GB right on their main
                page! It’s free and easy.
            </div>
            <div class="subtext">
                ▶ However, if you’re having a hard time providing a link to cloud storage, drop us an email at
                hello@oyepackaging.com and we’ll be more than happy to help!
            </div>
            <div class="close-text" @click="closePopup">
                close
            </div>
        </div>
        
    </div>
</template>
<script>
export default {
    methods: {
        closePopup() {
            this.$emit('close');
        },
        handleClick(event) {
            if (event.target.classList.contains('popup')) {
                this.closePopup();
            }
        }
    }
}
</script>
<style scoped>
.popup {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 99999999;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(0, 0, 0, 0.2);
}

.popup-container {
    padding: 50px 100px 50px 40px;
    max-width: 1030px;
    background-color: #fff;
    position: relative;
    overflow-y: scroll;
    max-height: 100%;
}

.close-text {
    color: var(--button-bg-2, #C3C3C3);
font-size: 16px;
margin-top: 50px;
text-align: center;
display: none;
text-decoration-line: underline;
}

.popup-container .close {
    position: absolute;
    top: 20px;
    right: 20px;
    z-index: 999999999;
}

.popup-container .close:hover {
    cursor: pointer;
}

.popup-title {
    color: #264EAB;
    font-family: 'RobotoMono-B', sans-serif;
    font-size: 16px;
    margin-bottom: 32px;
}

.popup-text {
    color: #264EAB;
    font-family: 'RobotoMono-R', sans-serif;
    font-size: 16px;
    margin-bottom: 40px;
    padding-left: 34px;

}

span.bold {
    color: #264EAB;
    font-family: 'RobotoMono-B', sans-serif;
    font-size: 16px;
}

.popup-text.wir {
    max-width: 770px;
}

.subtext {
    color: #264EAB;
    font-family: 'RobotoMono-R', sans-serif;
    font-size: 16px;
}

.subtext.f {
    margin-bottom: 34px;
}

.subtext a {
    color: #264EAB;
}


.subtext a:hover {
    color: #3771F8;
}


@media screen and (max-width: 768px) {
    .close-text {
        display: flex;
        justify-content: center;
    }
    .popup-container {
        padding: 20px;
        padding-top: 60px;
    }

    .popup-container .close {
        right: 12px;
    }
}

@media screen and (max-width: 600px) {
    .popup-text {
        padding-left: 0;
    }

    .popup-title {
        margin-bottom: 44px;
        max-width: 218px;
    }
}


@media screen and (max-width: 460px) {
    .popup-text, .popup-text.wir {
        max-width: 322px;
    }

}
</style>
