<template>
    <div v-if="showPopup1" class="popup" @click="closePopup">
        <div class="popup-content">
            <div class="popup-title">
                EU vs. China Food Packaging Regulations
            </div>
            <div class="popup-text">
                <span class="popup-title">
                    Regulatory Framework:
                </span>
                <ul>
                    <li><span class="bold"> EU:</span>
                        <a href="https://www.figma.com/exit?url=https%3A%2F%2Fop.europa.eu%2Fen%2Fweb%2Feu-law-in-force%2Fbibliographic-details%2F-%2Felif-publication%2F08a6f2ca-ddae-4630-801b-debd699a5af2"
                            target="_blank">Regulation (EC) No. 1935/2004</a>, ensuring high safety standards.
                    </li>
                    <li><span class="bold"> China:</span>
                        <a href="http://www.npc.gov.cn/zgrdw/englishnpc/Law/2011-02/15/content_1620635.htm"
                            target="_blank">, ensuring high safety standards.</a>
                        "Food Safety Law," but with differing specifics.
                    </li>
                </ul>
            </div>
            <div class="popup-text">
                <span class="popup-title">
                    Positive Lists:
                </span>
                <ul>
                    <li><span class="bold"> EU:</span> 'Positive list' for authorized substances (e.g.,<a
                            href="https://op.europa.eu/en/web/eu-law-in-force/bibliographic-details/-/elif-publication/84665a0a-f9e5-4eae-aac5-af4fd4c8ac94"
                            target="_blank">Regulation (EU) No. 10/2011)</a>
                    </li>
                    <li><span class="bold"> China:</span>'Positive lists' for various FCMs (e.g.,<a
                            href="https://www.chinesestandard.net/PDF.aspx/GB9685-2016" target="_blank">GB 9685)</a>
                    </li>
                </ul>
            </div>
            <div class="popup-text">
                <span class="popup-title">
                    Regulatory Framework:
                </span>
                <ul>
                    <li><span class="bold"> EU:</span>
                        Notable differences due to diverse scientific assessments and regulatory priorities.
                    </li>
                    <li><span class="bold"> China:</span>
                        Varying regulations due to scientific assessment, risk perception, and priorities
                    </li>
                </ul>
            </div>

            <div class="popup-text">
                <span class="popup-title">
                    Testing Methods:
                </span>
                <div class="text">
                    While both regions employ testing methods, the EU's approach is renowned for its meticulousness.
                </div>
            </div>

            <div class="popup-text">
                <span class="popup-title">
                    Recycled Materials:
                </span>
                <ul>
                    <li><span class="bold"> EU:</span>
                        Implements stringent criteria for recycled plastics, prioritizing safety.
                    </li>
                    <li><span class="bold"> China:</span>
                        Approaches to recycled materials may differ, potentially affecting safety.
                    </li>
                </ul>
            </div>
            <div class="popup-text">
                <span class="popup-title">
                    Other Materials:
                </span>
                <div class="text">
                    Regulations for auxiliary materials vary between regions, with European standards often considered more protective.
                </div>
            </div>
            <div class="popup-text">
                <span class="popup-title">
                    Innovation and New Materials:
                </span>
                <div class="text">
                    European approval processes generally prioritize safety, making them more reliable for innovative food contact substances.
                </div>
            </div>
            <div class="popup-close" @click="closePopup">
                <svg class="close" width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M9.165 9.13827C9.36026 8.94301 9.67685 8.94301 9.87211 9.13827L12.5186 11.7847L15.165 9.13827C15.3603 8.94301 15.6768 8.94301 15.8721 9.13827C16.0674 9.33353 16.0674 9.65011 15.8721 9.84537L13.2257 12.4918L15.8721 15.1383C16.0674 15.3335 16.0674 15.6501 15.8721 15.8454C15.6768 16.0406 15.3603 16.0406 15.165 15.8454L12.5186 13.1989L9.87211 15.8454C9.67685 16.0406 9.36026 16.0406 9.165 15.8454C8.96974 15.6501 8.96974 15.3335 9.165 15.1383L11.8114 12.4918L9.165 9.84537C8.96974 9.65011 8.96974 9.33353 9.165 9.13827Z" fill="#264EAB"/>
                    </svg>
                    
            </div>
            <div class="close-btn" @click="closePopup">
                close
            </div>
        </div>
    </div>
</template>
  
<script>
export default {
    props: {
        showPopup1: Boolean
    },
    methods: {
        closePopup() {
            if (event.target.classList.contains('popup') || event.target.classList.contains('close') || event.target.classList.contains('close-btn')) {
                this.$emit('close-popup');
            }

        }
    }
}
</script>
    .
<style scoped>
body {
    position: relative;
}

.popup {
    background: rgba(0, 0, 0, 0.2);
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 9999;
}

.popup-content {
    background: var(--bg-1, #FFF);
    box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.15);
    padding: 60px 40px;
    position: relative;
    overflow-y: scroll;
    height: fit-content;
}

.close-btn {
    color: #C3C3C3;
font-family: 'RobotoMono-R', sans-serif;
font-size: 16px;
text-decoration-line: underline;
margin-top: 25px;
display: flex;
    justify-content: center;
    display: none;
}

.popup-title {
    color: var(--button-blue, #264EAB);
    font-family: 'RobotoMono-B', sans-serif;
    font-size: 16px;
    line-height: normal;
    margin-bottom: 44px;
}

ul li {
    color: var(--button-blue, #264EAB);
    font-family: 'RobotoMono-R', sans-serif;
    font-size: 16px;
    line-height: normal;
    margin-bottom: 10px;
}

li a {
    color: var(--button-blue-hover, #3771F8);
    font-size: 16px;
    text-decoration-line: underline;
}

li .bold {
    margin-right: 5px;
    font-family: 'RobotoMono-B', sans-serif;
}

.text {
    padding-left: 20px;
    color: var(--button-blue, #264EAB);
font-family: 'RobotoMono-R', sans-serif;
font-size: 16px;
line-height: normal;
margin-top: 18px;
margin-bottom: 18px;
}

.popup-close {
    position: absolute;
    top: 40px;
    right: 40px;
}

.popup-close:hover {
    cursor: pointer;
}



@media screen and (max-width: 1300px) {
    .popup-content {
        padding-left: 20px;
        padding-right: 20px;
        overflow-y: scroll;
        height: 100%;
    }
}

@media screen and (max-width: 680px) {
    .popup-content {
        padding-top: 80px;
    }

    .popup-title:first-child {
        max-width: 280px
    }

    .popup-content {
        padding: 80px 40px 60px 40px;
    }

    ul {
        padding-left: 50px;
    }

    .text {
        padding-left: 30px;
    }
}

@media screen and (max-width: 650px) {
    .close-btn {
        display: flex;
    }
}

@media screen and (max-width: 450px) {
    ul {
        max-width: 280px;
    }
}

.popup-close:hover {
    cursor:pointer
}
</style>
  