<template>
  <newBluePop v-if="successPopupShow" @close="closePopup"/>
   
  <div class="container">
    <div class="form-title">
      Get in touch
    </div>
    <div class="form-subtitle">
      From basic inquiries to complex questions - drop us a line, we’re always more than happy to help!
    </div>
    <div class="form-section">
      <form>
        <div class="field-in">

          <input type="name"  v-model="name" :class="{ fields: true, error: nameError }"
          class="form-input"   required>
         <label class="form-label" v-if="!nameError">Your Name</label>
         <label class="form-label red" v-if="nameError">Your Name</label>

          <span class="input-error" v-if="nameError">
            <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M15.9993 29.3333C23.3631 29.3333 29.3327 23.3638 29.3327 16C29.3327 8.63616 23.3631 2.66663 15.9993 2.66663C8.63555 2.66663 2.66602 8.63616 2.66602 16C2.66602 23.3638 8.63555 29.3333 15.9993 29.3333Z"
                stroke="#F93232" stroke-width="1.33333" stroke-linecap="round" stroke-linejoin="round" />
              <path d="M20 12L12 20" stroke="#F93232" stroke-width="1.33333" stroke-linecap="round"
                stroke-linejoin="round" />
              <path d="M12 12L20 20" stroke="#F93232" stroke-width="1.33333" stroke-linecap="round"
                stroke-linejoin="round" />
            </svg>
            Please enter your name
          </span>
        </div>
        <div class="field-in">
          <input type="name" v-model="email" :class="{ fields: true, error: emailError }"
          class="form-input"   required>
         <label class="form-label" v-if="!emailError">Email Address</label>
         <label class="form-label red" v-if="emailError">Email Address</label>


         
          <span class="input-error" v-if="emailError">
            <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M15.9993 29.3333C23.3631 29.3333 29.3327 23.3638 29.3327 16C29.3327 8.63616 23.3631 2.66663 15.9993 2.66663C8.63555 2.66663 2.66602 8.63616 2.66602 16C2.66602 23.3638 8.63555 29.3333 15.9993 29.3333Z"
                stroke="#F93232" stroke-width="1.33333" stroke-linecap="round" stroke-linejoin="round" />
              <path d="M20 12L12 20" stroke="#F93232" stroke-width="1.33333" stroke-linecap="round"
                stroke-linejoin="round" />
              <path d="M12 12L20 20" stroke="#F93232" stroke-width="1.33333" stroke-linecap="round"
                stroke-linejoin="round" />
            </svg>
            Please enter your email address
          </span>
        </div>
        <div class="field-in textarea">
          <textarea type="name" v-model="message" :class="{ fields: true, error: messageError }"
          class="form-input"   required></textarea>
         <label class="form-label" v-if="!messageError">Message</label>
         <label class="form-label red" v-if="messageError">Message</label>

        
          <span class="input-error" v-if="messageError">
            <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M15.9993 29.3333C23.3631 29.3333 29.3327 23.3638 29.3327 16C29.3327 8.63616 23.3631 2.66663 15.9993 2.66663C8.63555 2.66663 2.66602 8.63616 2.66602 16C2.66602 23.3638 8.63555 29.3333 15.9993 29.3333Z"
                stroke="#F93232" stroke-width="1.33333" stroke-linecap="round" stroke-linejoin="round" />
              <path d="M20 12L12 20" stroke="#F93232" stroke-width="1.33333" stroke-linecap="round"
                stroke-linejoin="round" />
              <path d="M12 12L20 20" stroke="#F93232" stroke-width="1.33333" stroke-linecap="round"
                stroke-linejoin="round" />
            </svg>
            Don't forget to fill in the Message field 👀
          </span>
        </div>
        <div class="checkbox-input" :class="{ 'error-checkbox': checkboxError }">
          <input type="checkbox" v-model="checkbox" class="custom-checkbox custom" id="happy" name="agree" value="yes">
          <label :for="checkboxError ? 'happy' : 'happy'" :class="{ 'error-label': checkboxError }">
            I consent to OyePackaging storing my submitted information so they can respond to my inquiry
          </label>
        </div>
        <button type="button" @click="sendEmail">SUBMIT</button>
      </form>
      <div class="contact-info">
        <div class="adress-section">
          <div class="adress-title">
            ADDRESS
          </div>
          <div class="adress">
            Päevalille tn 6-15, <br> 13517, Tallinn, Estonia
          </div>
        </div>
        <div class="contact-schoose">
          <div class="title">
            CONTACT US
          </div>
          <div class="contacts-e">
            <a href="tel+31 970 1028 0877">+31 970 1028 0877</a>
            <a href="mailto:hello@oyepackaging.com">hello@oyepackaging.com</a>
          </div>
         
        </div>
        <div class="follow-us">
          <div class="fs-title">
            FOLLOW US
          </div>
          <div class="socials">
            <a href="https://www.instagram.com/hello_oye_packaging/" target="_blank">
              <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M21.3333 4H10.6667C6.98477 4 4 6.98477 4 10.6667V21.3333C4 25.0152 6.98477 28 10.6667 28H21.3333C25.0152 28 28 25.0152 28 21.3333V10.6667C28 6.98477 25.0152 4 21.3333 4ZM25.6667 21.3333C25.6593 23.7235 23.7235 25.6593 21.3333 25.6667H10.6667C8.27647 25.6593 6.34065 23.7235 6.33333 21.3333V10.6667C6.34065 8.27647 8.27647 6.34065 10.6667 6.33333H21.3333C23.7235 6.34065 25.6593 8.27647 25.6667 10.6667V21.3333ZM22.3333 11C23.0697 11 23.6667 10.403 23.6667 9.66667C23.6667 8.93029 23.0697 8.33333 22.3333 8.33333C21.5969 8.33333 21 8.93029 21 9.66667C21 10.403 21.5969 11 22.3333 11ZM16 10C12.6863 10 10 12.6863 10 16C10 19.3137 12.6863 22 16 22C19.3137 22 22 19.3137 22 16C22.0036 14.4076 21.3725 12.8794 20.2465 11.7534C19.1205 10.6275 17.5924 9.99645 16 10ZM12.3333 16C12.3333 18.0251 13.9749 19.6667 16 19.6667C18.0251 19.6667 19.6667 18.0251 19.6667 16C19.6667 13.9749 18.0251 12.3333 16 12.3333C13.9749 12.3333 12.3333 13.9749 12.3333 16Z"
                  fill="#264EAB" />
              </svg>
            </a>
            <a href="https://www.facebook.com/oyepackaging/" target="_blank">
              <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M21.9997 8H17.9997C17.2633 8 16.6663 8.59696 16.6663 9.33333V13.3333H21.9997C22.1513 13.33 22.2951 13.4005 22.3853 13.5225C22.4754 13.6445 22.5007 13.8027 22.453 13.9467L21.4663 16.88C21.3754 17.1492 21.1238 17.3312 20.8397 17.3333H16.6663V27.3333C16.6663 27.7015 16.3678 28 15.9997 28H12.6663C12.2982 28 11.9997 27.7015 11.9997 27.3333V17.3333H9.99967C9.63149 17.3333 9.33301 17.0348 9.33301 16.6667V14C9.33301 13.6319 9.63149 13.3333 9.99967 13.3333H11.9997V9.33333C11.9997 6.38781 14.3875 4 17.333 4H21.9997C22.3678 4 22.6663 4.29848 22.6663 4.66667V7.33333C22.6663 7.70152 22.3678 8 21.9997 8Z"
                  fill="#264EAB" />
              </svg>

            </a>
            <a href="https://www.linkedin.com/company/hello-oye-packaging" target="_blank">
              <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M6.66667 4H25.3333C26.8061 4 28 5.19391 28 6.66667V25.3333C28 26.8061 26.8061 28 25.3333 28H6.66667C5.19391 28 4 26.8061 4 25.3333V6.66667C4 5.19391 5.19391 4 6.66667 4ZM10.6667 24C11.0349 24 11.3333 23.7015 11.3333 23.3333V14C11.3333 13.6319 11.0349 13.3333 10.6667 13.3333H8.66667C8.29848 13.3333 8 13.6319 8 14V23.3333C8 23.7015 8.29848 24 8.66667 24H10.6667ZM9.66667 12C8.56209 12 7.66667 11.1046 7.66667 10C7.66667 8.89543 8.56209 8 9.66667 8C10.7712 8 11.6667 8.89543 11.6667 10C11.6667 11.1046 10.7712 12 9.66667 12ZM23.3333 24C23.7015 24 24 23.7015 24 23.3333V17.2C24.0433 15.0811 22.4768 13.2727 20.3733 13.0133C18.9027 12.879 17.4777 13.5659 16.6667 14.8V14C16.6667 13.6319 16.3681 13.3333 16 13.3333H14C13.6319 13.3333 13.3333 13.6319 13.3333 14V23.3333C13.3333 23.7015 13.6319 24 14 24H16C16.3681 24 16.6667 23.7015 16.6667 23.3333V18.3333C16.6667 17.2288 17.5621 16.3333 18.6667 16.3333C19.7712 16.3333 20.6667 17.2288 20.6667 18.3333V23.3333C20.6667 23.7015 20.9652 24 21.3333 24H23.3333Z"
                  fill="#264EAB" />
              </svg>

            </a>
            <a href="https://wa.me/+3197010280877" target="_blank">
              <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M16.0503 4.38721C9.46348 4.38721 4.10297 9.5503 4.10065 15.8956C4.0991 17.9248 4.65032 19.9051 5.69548 21.6494L4 27.613L10.3352 26.0128C12.0975 26.9346 14.0569 27.4154 16.0457 27.414H16.0503C22.6372 27.414 27.9977 22.2502 28 15.9049C28.0015 12.8313 26.7597 9.93818 24.503 7.76347C22.247 5.58798 19.247 4.38798 16.0503 4.38721ZM16.0503 25.47H16.0465C14.2643 25.47 12.5161 25.0086 10.991 24.1369L10.6271 23.9294L6.86916 24.8786L7.87252 21.3482L7.63639 20.9867C6.64457 19.474 6.11659 17.7044 6.11742 15.8956C6.11974 10.621 10.576 6.33121 16.0542 6.33121C18.7066 6.33198 21.2003 7.32837 23.0761 9.13611C24.952 10.9439 25.984 13.3477 25.9825 15.9041C25.9801 21.1787 21.5246 25.47 16.0495 25.47H16.0503ZM21.4983 18.3049C21.1995 18.1617 19.7316 17.4657 19.4575 17.3689C19.1843 17.2737 18.9853 17.2241 18.7863 17.5121C18.5881 17.8001 18.0152 18.4481 17.8418 18.6393C17.6668 18.8313 17.4926 18.8546 17.1938 18.7113C16.895 18.5673 15.9326 18.2639 14.793 17.2837C13.905 16.5219 13.3058 15.5805 13.1316 15.2917C12.9574 15.0045 13.113 14.8489 13.2625 14.7057C13.3964 14.5779 13.5613 14.3704 13.7099 14.2024C13.8586 14.0344 13.9081 13.9144 14.0088 13.7224C14.1079 13.5312 14.0583 13.3624 13.9832 13.2192C13.9081 13.0744 13.312 11.6592 13.0619 11.084C12.8204 10.5235 12.5742 10.5986 12.3907 10.5893C12.2165 10.5815 12.0183 10.5792 11.8178 10.5792C11.6204 10.5792 11.296 10.6512 11.0219 10.9392C10.7486 11.2272 9.97677 11.9224 9.97677 13.3377C9.97677 14.7537 11.0467 16.1209 11.1961 16.3129C11.3455 16.5041 13.3019 19.4097 16.2973 20.6561C17.0095 20.9511 17.5654 21.1284 17.9997 21.2615C18.7151 21.4806 19.3662 21.4489 19.8803 21.3753C20.4532 21.2925 21.647 20.6801 21.8947 20.0089C22.144 19.3377 22.144 18.7617 22.0697 18.6417C21.9969 18.5217 21.7972 18.4497 21.4983 18.3049Z"
                  fill="#264EAB" />
              </svg>

            </a>
            <a href="https://t.me/hellooyepackagingbot" target="_blank">
              <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M29.3337 16.0001C29.3337 23.3639 23.3641 29.3334 16.0003 29.3334C8.63653 29.3334 2.66699 23.3639 2.66699 16.0001C2.66699 8.63628 8.63653 2.66675 16.0003 2.66675C23.3641 2.66675 29.3337 8.63628 29.3337 16.0001ZM16.4781 12.51C15.1812 13.0494 12.5893 14.1659 8.7024 15.8593C8.07123 16.1103 7.74059 16.3559 7.71049 16.596C7.65962 17.0018 8.16776 17.1615 8.85971 17.3791C8.95384 17.4087 9.05136 17.4394 9.15134 17.4719C9.83212 17.6932 10.7479 17.9521 11.224 17.9624C11.6558 17.9717 12.1378 17.7937 12.6699 17.4283C16.3016 14.9768 18.1763 13.7377 18.294 13.711C18.377 13.6921 18.4921 13.6684 18.57 13.7377C18.648 13.807 18.6403 13.9382 18.6321 13.9734C18.5817 14.188 16.5871 16.0424 15.5549 17.0021C15.2331 17.3012 15.0048 17.5135 14.9582 17.5619C14.8536 17.6705 14.7471 17.7732 14.6447 17.8719C14.0123 18.4816 13.538 18.9388 14.671 19.6854C15.2154 20.0442 15.6511 20.3409 16.0858 20.6369C16.5605 20.9602 17.0339 21.2826 17.6465 21.6842C17.8026 21.7865 17.9517 21.8927 18.0968 21.9962C18.6493 22.3901 19.1456 22.7439 19.7587 22.6875C20.115 22.6547 20.483 22.3197 20.6699 21.3205C21.1116 18.9592 21.9799 13.8428 22.1805 11.7345C22.1981 11.5498 22.176 11.3134 22.1582 11.2096C22.1405 11.1058 22.1034 10.958 21.9685 10.8485C21.8087 10.7189 21.5621 10.6916 21.4518 10.6935C20.9503 10.7024 20.1809 10.9699 16.4781 12.51Z"
                  fill="#264EAB" />
              </svg>


            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from 'axios';
import newBluePop from '@/components/newBluePop.vue';

export default {
  mounted () {
    this.date = new Date().toLocaleString();
    console.log(this.date)
  },
  components: {
    newBluePop
  },
  data() {
    return {
      name: '',
      email: '',
      message: '',
      checkbox: false,
      nameError: false,
      emailError: false,
      messageError: false,
      isSending: false, 
      checkboxError: false,
      successPopupShow: false,
      date: ''
    };
  },
  methods: {
    async sendEmail() {
      this.nameError = !this.name;
      this.emailError = !this.email || !this.isValidEmail(this.email);
      this.messageError = !this.message;
      this.checkboxError = !this.checkbox;

      if (!this.nameError && !this.emailError && !this.messageError && !this.checkboxError) {
        try {
          this.isSending = true;

          const response = await axios.post('./api/sendEmail.php', {
            recipient: 'hello@oyepackaging.com',
            subject: 'Message from client OYE',
            message: this.message,
            data: {
              date: this.date,
              name: this.name,
              email: this.email,
              checkbox: this.checkbox,
            },
          });
          this.successPopupShow = true;
          console.log('Message', response.data);
          // setTimeout(() => {
            // window.location.href = '/'; //Will take you to Google.
          // }, 5000)
        } catch (error) {
          console.error('Ошибка при отправке письма:', error);
        } finally {
          this.isSending = false;
        }
      }
    },
    isValidEmail(email) {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return emailRegex.test(email);
    },
    closePopup() {
      this.successPopupShow = false;
    },
    closeLayoutPopup() {
      this.successPopupShow = false
    },
  },
};
</script>
<style scoped>
.container {
  max-width: 1240px;
  margin: 0 auto;
  margin-top: 100px;
}

.form-title {
  color: #264EAB;
  font-size: 80px;
  font-family: 'RobotoMono-R', sans-serif;
  margin-bottom: 50px;

}

.form-subtitle {
  max-width: 767px;
  color: #264EAB;
  font-size: 32px;
  font-family: 'RobotoMono-L', sans-serif;
  margin-bottom: 38px;
}

.form-section {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}


form .fields {
  border-radius: 10px;
  background: var(--bg-2, #F5F5F5);
  width: 750px;
  height: 67px;
  outline: 0;
  border: 0;
  padding-left: 40px;
  margin-bottom: 20px;
  padding-right: 40px;
}


.custom-checkbox {
  position: absolute;
  z-index: -1;
  opacity: 0;
  max-width: 600px;
}


.custom-checkbox+label {
  display: inline-flex;
  align-items: center;
  user-select: none;
  font-size: 16px;
  font-family: 'RobotoMono-R', sans-serif;
  color: #264EAB;
  max-width: 485px;
  display: flex;
  align-items: flex-start;
  line-height: 20px;
  margin-bottom: 45px;
}


.custom-checkbox+label::before {
  content: '';
  display: inline-block;
  width: 1em;
  height: 1em;
  flex-shrink: 0;
  flex-grow: 0;
  border: 1px solid #264EAB;
  margin-right: 0.5em;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 50% 50%;
  max-width: 600px;
}


.form-input {
  width: 100%;
  height: 40px;
  padding: 5px 15px;
}

.form-label {
  position: absolute;
  pointer-events: none;
  left: 40px;
  right: 15px;
  top: 20px;
  white-space: nowrap;
  overflow: hidden;
  line-height: 24px;
  transition: .3s;
  color: var(--main-blue, #264EAB);
    font-family: 'RobotoMono-L', sans-serif;
    font-size: 24px;
}

.form-input:focus~.form-label,
.form-input:not(:focus):valid~.form-label {
  top: -11px;
  font-size: 24px;
  width: unset;
  padding: 0 10px; 
  line-height: 24px;
  z-index: 22;
  width: fit-content;
}

.textarea .form-input:not(:focus):valid~.form-label, .textarea .form-input:focus~.form-label {
  top: -15px;
}


.form-input:focus~.form-label::before, .form-label.red::before {
  content: " ";
  width: 101%;
  background: #fff;
  height: calc(24px - 34%);
  position: absolute;
  top: -3px;
  left: 0;
  z-index: -1;
  transition: 0.4s;
}

.form-label.red {
  color: red;
}

.field-in  .form-input:focus {
  border: 1px solid #264EAB;
}


.field-in {
  display: inline-block;
  vertical-align: top;
  position: relative;
}

.custom-checkbox:checked+label::before {
  content: '•';
    font-size: 40px;
    width: 16px;
    height: 16px;
    border-color: #0b76ef;
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: 0px;
    margin-right: 8px;
}

.custom-checkbox:checked+label::before text {
  margin-top: -20px;
}


form button {
  border: 4px solid var(--button-blue, #264EAB);
  color: var(--button-blue, #264EAB);
  font-family: 'RobotoMono-M', sans-serif;
  width: 241px;
  height: 54px;
  background-color: #fff;
  font-size: 18px;
  transition: 0.3s;
  margin-bottom: 100px;
}

form button:hover {
  background-color: #264EAB;
  color: #fff;
  cursor: pointer;
}


.checkbox-input {
  max-width: 600px;
}

.contact-info {
  max-width: 349px;
}

.adress-section,
.contact-schoose {
  font-size: 24px;
  font-family: 'RobotoMono-L', sans-serif;
  color: #264EAB;
  margin-bottom: 38px;
}


form {
  max-width: 600px;
}


.adress-title,
.title,
.fs-title {
  font-size: 24px;
  font-family: 'RobootoMono-R', sans-serif;
}


.contact-schoose a,
.fs-title {
  color: #264EAB;
  text-decoration: none;
}

.input-error {
  display: flex;
  align-items: center;
  color: #FF0000;
  margin-bottom: 20px;
  position: absolute;
  bottom: -35px;
}

.input-error svg {
  margin-right: 8px;
}

.field-in {
  position: relative;
  margin-bottom: 20px;
}


#message {
  height: 200px;
  padding-top: 30px;
}

.fields::placeholder,
.fields {
  color: var(--main-blue, #264EAB);
  /* Button Link/NORMAL */
  font-family: 'RobotoMono-L', sans-serif;
  font-size: 24px;
}

.contacts-e {
  display: flex;
  flex-direction: column;
}

.fields.error {
  border: 1px solid #FF0000;
  background: #FFE7E7;
  transition: 0.3s;
  margin-bottom: 0;

}

.fields.error::placeholder {
  color: #FF0000;
}

.fields.error {
  margin-bottom: 20px !important;
}


.checkbox-input.error-checkbox .custom-checkbox+label::before {
  border: 1px solid #FF0000;
}

.checkbox-input.error-checkbox .custom-checkbox+label {
  color: #FF0000;

}

form textarea.fields {
  resize: none;
  height: 200px!important;
  padding-bottom: 20px!important;
  padding-top: 20px;
}

form textarea.form-input:focus~.form-label::before, form textarea.form-label.red::before {
  top: 3px;
  line-height: 100%;
}


textarea.form-input:focus~.form-label, textarea.form-input:not(:focus):valid~.form-label {
  line-height: 28px;
}


@media screen and (max-width: 1280px) {
  .container {
    padding-left: 20px;
    padding-right: 20px;
  }

  .adress-section,
  .contact-schoose {
    font-size: 19px;
  }
}

@media screen and (max-width: 1170px) {
  .form-title {
    font-size: 60px;
  }

  .form-subtitle {
    font-size: 26px;
    max-width: 600px;
  }

  .fields::placeholder,
  .fields {
    font-size: 17px;
  }

  form .fields {
    width: 600px;
  }
}


@media screen and (max-width: 1030px) {
  form .fields {
    width: 500px;
  }
}

@media screen and (max-width: 896px) {
  .form-section {
    flex-direction: column;
  }

  form {
    max-width: unset;
    width: 100%;
  }

  .field-in {
    width: 100%;
  }

  form .fields {
    width: 100% !important;
  }
}


@media screen and (max-width: 630px) {
  .input-error {
    font-size: 12px;
    top: 50px;
  }

  .input-error svg {
    max-width: 20px;
    max-height: 20px;
  }

  .form-input:focus~.form-label:before {
    top: -7px;
  }

  .form-input:focus~.form-label, .form-input:not(:focus):valid~.form-label {
    top: 0;
  }
}



@media screen and (max-width: 600px) {
  .form-title {
    font-size: 25px;
    margin-bottom: 25px;
  }

  .form-subtitle {
    font-size: 19px;
  }

  .container {
    margin-top: 50px;
  }

  .field-in {
    margin-bottom: 0;
  }

  .checkbox-input {
    margin-top: 50px;
  }

  form button {
    margin-bottom: 25px;
  }

  .contact-info {
    margin-bottom: 50px;
  }

  .title, .adress-title, .fs-title {
    font-family: 'RobotoMono-L', sans-serif;
  }

  .adress-section, .contact-schoose {
    font-size: 16px;
  }

}





@media screen and (max-width: 804px) {
  .field-in .form-label {
    font-size: 18px;
  }

  .form-input:focus~.form-label,
.form-input:not(:focus):valid~.form-label {
  font-size: 18px;
  line-height: 18px;
}



  
}

@media screen and (max-width: 630px) {
  .field-in .form-label{
    top: 20px;
  }

  .form-input:focus~.form-label, .form-input:not(:focus):valid~.form-label {
    top: -12px;
  }

  .field-in {
    margin-bottom: 15px;
  }
}


@media screen and (max-width: 450px) {
  .input-error {
    top: 63px;
  }
}


</style>