<template>
    <div v-if="showPopup4" class="popup" @click="closePopup">
        <div class="popup-content">
            <div class="popup-title">
                Environmental Factors Shaping Food Packaging Decisions
            </div>
          
            <div class="popup-text">
                <span class="popup-title">
                    Temperature Adaptation:
                </span>
                <div class="text">
                    Designed to endure varying European temperatures, ensuring product freshness and packaging integrity.
                </div>
            </div>

          
            <div class="popup-text">
                <span class="popup-title">
                    Effective Moisture Control:
                </span>
                <div class="text">
                    Takes into account regional humidity levels, preventing issues like weakening and moisture buildup.
                </div>
            </div>
            <div class="popup-text">
                <span class="popup-title">
                    UV Protection:
                </span>
                <div class="text">
                    Incorporates UV-resistant layers to safeguard both the product and packaging material.
                </div>
            </div>
            <div class="popup-text">
                <span class="popup-title">
                    Versatile for Varied Transport:
                </span>
                <div class="text">
                    Engineered to withstand diverse conditions during transportation and storage in Europe's climate zones.
                </div>
            </div>
            <div class="popup-text">
                <span class="popup-title">
                    Local Preference Alignment:
                </span>
                <div class="text">
                    Tailored to European consumption habits, accommodating both hot and cold beverages.
                </div>
            </div>
            <div class="popup-text">
                <span class="popup-title">
                    Sustainability & Local Sourcing:
                </span>
                <div class="text">
                    Frequently utilizes sustainable, locally sourced materials, reducing environmental impact.
                </div>
            </div>
            <div class="popup-text">
                <span class="popup-title">
                    Meeting Shelf Life Expectations:
                </span>
                <div class="text">
                    Meets European consumer expectations for product shelf life in this climate.
                </div>
            </div>
            <div class="popup-text">
                <span class="popup-title">
                    Customized for Local Needs:
                </span>
                <div class="text">
                    Often tailored to specific European market requirements.
                </div>
            </div>
            <div class="popup-close" @click="closePopup">
                <svg class="close" width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M9.165 9.13827C9.36026 8.94301 9.67685 8.94301 9.87211 9.13827L12.5186 11.7847L15.165 9.13827C15.3603 8.94301 15.6768 8.94301 15.8721 9.13827C16.0674 9.33353 16.0674 9.65011 15.8721 9.84537L13.2257 12.4918L15.8721 15.1383C16.0674 15.3335 16.0674 15.6501 15.8721 15.8454C15.6768 16.0406 15.3603 16.0406 15.165 15.8454L12.5186 13.1989L9.87211 15.8454C9.67685 16.0406 9.36026 16.0406 9.165 15.8454C8.96974 15.6501 8.96974 15.3335 9.165 15.1383L11.8114 12.4918L9.165 9.84537C8.96974 9.65011 8.96974 9.33353 9.165 9.13827Z" fill="#264EAB"/>
                    </svg>
                    
            </div>
            <div class="close-btn" @click="closePopup">
                close
            </div>
        </div>
    </div>
</template>
  
<script>
export default {
    props: {
        showPopup4: Boolean
    },
    methods: {
        closePopup() {
            if (event.target.classList.contains('popup') || event.target.classList.contains('close') || event.target.classList.contains('close-btn')) {
                this.$emit('close-popup');
            }
        }
    }
}
</script>
    .
<style scoped>
body {
    position: relative;
}

.popup {
    background: rgba(0, 0, 0, 0.2);
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 9999;

}

.popup-content {
    background: var(--bg-1, #FFF);
    box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.15);
    padding: 60px 40px;
    position: relative;
    max-width: 1000px;
    height: 80%;
    overflow-y: scroll;
}

.popup-title {
    color: var(--button-blue, #264EAB);
    font-family: 'RobotoMono-B', sans-serif;
    font-size: 16px;
    line-height: normal;
    margin-bottom: 44px;
}

.close-btn {
    color: #C3C3C3;
font-family: 'RobotoMono-R', sans-serif;
font-size: 16px;
text-decoration-line: underline;
margin-top: 25px;
display: flex;
    justify-content: center;
    display: none;
}

ul li {
    color: var(--button-blue, #264EAB);
    font-family: 'RobotoMono-R', sans-serif;
    font-size: 16px;
    line-height: normal;
    margin-bottom: 10px;
}

li a {
    color: var(--button-blue-hover, #3771F8);
    font-size: 16px;
    text-decoration-line: underline;
}

li .bold {
    margin-right: 5px;
    font-family: 'RobotoMono-B', sans-serif;
}

.text {
    padding-left: 20px;
    color: var(--button-blue, #264EAB);
font-family: 'RobotoMono-R', sans-serif;
font-size: 16px;
line-height: normal;
margin-top: 18px;
margin-bottom: 18px;
}

.popup-close {
    position: absolute;
    top: 40px;
    right: 40px;
}

.popup-close:hover {
    cursor: pointer;
}



@media screen and (max-width: 1300px) {
    .popup-content {
        padding-left: 20px;
        padding-right: 20px;
        overflow-y: scroll;
        height: fit-content;
    }
}


@media screen and (max-width: 680px) {
    .popup-content {
        padding-top: 80px;
    }

    .popup-title:first-child {
        max-width: 280px
    }

    .popup-content {
        padding: 80px 40px 60px 40px;
    }

    ul {
        padding-left: 50px;
    }

    .text {
        padding-left: 30px;
    }
}

@media screen and (max-width: 650px) {
    .popup-content {
        height: 100%;
        max-height: unset;
    }
}


@media screen and (max-width: 650px) {
    .close-btn {
        display: flex;
    }
}
@media screen and (max-width: 450px) {
    ul {
        max-width: 280px;
    }
}

.popup-close:hover {
    cursor:pointer
}
</style>
  