<template>
    <div class="drip-bag-section">
        <div class="delivery-container">
            <div class="delivery-text">
                <div class="delivery-title">
                    Your Coffee <br> in DRIP BAGS
                </div>
                <div class="delivery-subtitle">
                    Get your fully customized drip bags from 1000 pieces
                </div>
                <div class="buttons">
                    <TransparentButton :path="'/call'" :text="'discuss project'" />
                    <blueBtn />
                </div>
            </div>
            <div class="delivery-img">
                <img src="../assets/img/dripBag/dripbag.webp" alt="Delivery image">
            </div>
        </div>
        <div class="description-container">
            <img src="../assets/img/dripBag/drip-coffee-img.png" alt="">
            <div class="description-text">
                Drip coffee is ground coffee in filter bags,which resembles a pour-over. This is a complex product in a
                simple form that is easy to brew wherever you go.
            </div>
        </div>
    </div>
    <div class="gray-section">
        <div class="gray-section__container">
            <div class="gray-section__title">
                What We Offer
            </div>
            <div class="gray-section__text">
                The first all-inclusive "white label" drip coffee filling service in the EU. <br><br>

                Customize your drip bag coffee to your preferences. <br>
                Send us your coffee, select the grind level, volume, and drip packaging material with a unique design,
                along with the drip box, and receive regular deliveries to your doorstep.
            </div>
        </div>
    </div>
    <div class="product-description">
        <div class="product-description__container">
            <div class="pd-item-box">
                <div class="item">
                    <img src="../assets/img/dripBag/desc1.png" alt="">
                    <div class="title">
                        DRIP BAG
                    </div>
                    <div class="description">
                        We grind and fill your coffee with nitrogen into drip filters.
                    </div>
                </div>

                <div class="item">
                    <img src="../assets/img/dripBag/desc2.png" alt="">
                    <div class="title">
                        DRIP PACKAGING
                    </div>
                    <div class="description">
                        We go with the recyclable materials and customize the packaging.
                    </div>
                </div>

                <div class="item">
                    <img src="../assets/img/dripBag/desc3.png" alt="">
                    <div class="title">
                        DRIP BOX
                    </div>
                    <div class="description">
                        We provide drip boxes that can be printed or blank for your customization.
                    </div>
                </div>
            </div>
        </div>
        <div class="product-description__mobile">
            <swiper :slides-per-view="1" :modules="modules" :space-between="50" @swiper="onSwiper"
                @slideChange="onSlideChange" :pagination="{ clickable: true }" :loop="true">
                <div class="swiper-pagination"></div>

                <swiper-slide>
                    <div class="item">
                        <img src="../assets/img/dripBag/desc1.png" alt="">
                        <div class="title">
                            DRIP BAG
                        </div>
                        <div class="description">
                            We grind and fill your coffee with nitrogen into drip filters.
                        </div>
                    </div>
                </swiper-slide>
                <swiper-slide>
                    <div class="item">
                        <img src="../assets/img/dripBag/desc2.png" alt="">
                        <div class="title">
                            DRIP PACKAGING
                        </div>
                        <div class="description">
                            We go with the recyclable materials and customize the packaging.
                        </div>
                    </div>
                </swiper-slide>
                <swiper-slide>
                    <div class="item">
                        <img src="../assets/img/dripBag/desc3.png" alt="">
                        <div class="title">
                            DRIP BOX
                        </div>
                        <div class="description">
                            We provide drip boxes that can be printed or blank for your customization.
                        </div>
                    </div>
                </swiper-slide>
            </swiper>
        </div>
        <div class="product-full-description__cotainer">
            <div class="item">
                <div class="mobile-title">
                    CHOOSE YOUR DRIP BAG
                </div>
                <div class="item-img">
                    <img src="../assets/img/dripBag/desc-full1.png" alt="">
                </div>
                <div class="item-default-text">
                    <div class="title main">
                        Choose your Drip Bag
                    </div>
                    <div class="properties">
                        <div class="property">
                            <div class="title">
                                FILTER VOLUME:
                            </div>
                            <div class="text">
                                from 10 to 15 grams
                            </div>
                        </div>

                        <div class="property">
                            <div class="title">
                                MODEL AND MATERIAL:
                            </div>
                            <div class="text">
                                Non-woven pachit
                            </div>
                        </div>

                        <div class="property">
                            <div class="title">
                                FILTER:
                            </div>
                            <div class="text">
                                from Japan
                            </div>
                        </div>

                        <div class="property">
                            <div class="title">
                                PRESERVATION:
                            </div>
                            <div class="text">
                                Neutral nitrogen infusion
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="item">
                <div class="mobile-title">
                    CHOOSE YOUR DRIP PACKAGING
                </div>
                <div class="item-img">
                    <img src="../assets/img/dripBag/desc-full2.png" alt="">
                </div>
                <div class="item-default-text">
                    <div class="title main">
                        Choose your Drip Packaging
                    </div>
                    <div class="properties">
                        <div class="property">
                            <div class="title">
                                MATERIAL:
                            </div>
                            <div class="text">
                                Recyclable plastic
                                Recyclable paper
                            </div>
                        </div>

                        <div class="property">
                            <div class="title">
                                DRIP DIMENSIONS:
                            </div>
                            <div class="text">
                                100 mm x 120 mm
                            </div>
                        </div>
                    </div>
                    <div class="logo-property">
                        <div class="title">
                            TOUCH & FEEL EFFECT:
                        </div>
                        <div class="logo-container">
                            <div class="logo">
                                <svg width="52.000000" height="52.000000" viewBox="0 0 52 52" fill="none"
                                    xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                                    <defs />
                                    <path id="Vector"
                                        d="M43.17 43.21C38.48 47.9 32.13 50.53 25.5 50.53C22.21 50.53 18.96 49.89 15.93 48.63C12.89 47.37 10.14 45.53 7.82 43.21C3.13 38.52 0.5 32.16 0.5 25.53C0.5 18.9 3.13 12.54 7.82 7.86C12.51 3.17 18.86 0.53 25.5 0.53C32.13 0.53 38.48 3.17 43.17 7.86C47.86 12.54 50.5 18.9 50.5 25.53C50.5 32.16 47.86 38.52 43.17 43.21Z"
                                        stroke="#264EAB" stroke-opacity="1.000000" stroke-width="2.000000"
                                        stroke-linejoin="round" />
                                    <path id="Vector" d="M5.74 33.69L44.98 33.69" stroke="#264EAB"
                                        stroke-opacity="1.000000" stroke-width="2.000000" stroke-linejoin="round"
                                        stroke-linecap="round" />
                                    <path id="Vector"
                                        d="M17.79 28.5C17.66 27.09 17.03 25.77 16.02 24.77C15.05 23.84 13.8 23.24 12.46 23.09C13.92 23.09 15.33 22.52 16.38 21.5C17.31 20.54 17.85 19.26 17.87 17.93C17.89 19.25 18.4 20.52 19.32 21.48C19.83 21.99 20.44 22.4 21.12 22.68C21.79 22.95 22.51 23.09 23.24 23.09C21.76 23.12 20.36 23.72 19.32 24.77C18.34 25.77 17.8 27.1 17.79 28.5Z"
                                        stroke="#264EAB" stroke-opacity="1.000000" stroke-width="2.000000"
                                        stroke-linejoin="round" />
                                    <path id="Vector"
                                        d="M31.96 22.64C31.62 23.51 31.47 24.43 31.51 25.36C31.45 23.6 30.76 21.92 29.55 20.63C28.86 19.93 28.03 19.37 27.12 18.98C26.21 18.59 25.23 18.39 24.24 18.38C25.24 18.38 26.23 18.19 27.16 17.8C28.09 17.42 28.93 16.85 29.63 16.14C30.89 14.8 31.59 13.03 31.59 11.18C31.55 13.01 32.22 14.78 33.46 16.13C34.82 17.53 36.69 18.34 38.65 18.38C37.7 18.34 36.75 18.49 35.86 18.83C34.97 19.17 34.16 19.68 33.48 20.34C32.81 20.99 32.3 21.78 31.96 22.64Z"
                                        stroke="#264EAB" stroke-opacity="1.000000" stroke-width="2.000000"
                                        stroke-linejoin="round" />
                                    <path id="Vector" d="M15.04 39.71L35.93 39.71" stroke="#264EAB"
                                        stroke-opacity="1.000000" stroke-width="2.000000" stroke-linejoin="round"
                                        stroke-linecap="round" />
                                </svg>
                                <span>Glossy</span>
                            </div>

                            <div class="logo">
                                <svg width="59.000000" height="44.000000" viewBox="0 0 59 44" fill="none"
                                    xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                                    <defs />
                                    <path id="Vector"
                                        d="M50.38 20.2C50.38 18.67 50.08 17.15 49.49 15.73C48.9 14.32 48.04 13.03 46.96 11.94C45.87 10.86 44.59 10 43.17 9.41C41.75 8.83 40.23 8.52 38.7 8.52C37.41 8.56 36.14 8.81 34.94 9.27C34.07 6.79 32.45 4.64 30.31 3.12C28.16 1.61 25.6 0.79 22.97 0.79C21.3 0.79 19.65 1.12 18.1 1.76C16.56 2.4 15.15 3.34 13.97 4.52C12.79 5.7 11.85 7.11 11.21 8.65C10.57 10.2 10.24 11.85 10.24 13.53C10.24 13.77 10.24 13.98 10.24 14.22C8.93 14.22 7.64 14.48 6.44 14.98C5.23 15.48 4.14 16.21 3.22 17.13C2.29 18.05 1.56 19.15 1.06 20.35C0.56 21.56 0.31 22.85 0.31 24.15C0.34 26.5 1.21 28.75 2.77 30.51C4.32 32.27 6.46 33.41 8.78 33.73C9.18 35.98 10.36 38.01 12.11 39.48C13.86 40.95 16.07 41.76 18.35 41.77C19.9 41.75 21.42 41.37 22.78 40.64C24.15 39.92 25.32 38.88 26.2 37.61C26.86 39 27.9 40.18 29.2 41C30.51 41.83 32.01 42.27 33.55 42.27C35.12 42.28 36.66 41.83 37.98 40.98C39.3 40.13 40.35 38.92 41 37.49C42.74 39.18 45.08 40.13 47.51 40.14C50.05 40.14 52.48 39.13 54.28 37.34C56.07 35.54 57.08 33.11 57.08 30.57C57.05 28.52 56.36 26.53 55.09 24.91C53.83 23.29 52.08 22.12 50.09 21.59C50.23 21.14 50.32 20.67 50.38 20.2Z"
                                        stroke="#264EAB" stroke-opacity="1.000000" stroke-width="2.370391"
                                        stroke-linejoin="round" />
                                    <path id="Vector"
                                        d="M27.62 11.28C27.07 10.47 26.33 9.81 25.47 9.35C24.6 8.89 23.64 8.65 22.66 8.64C21.88 8.64 21.1 8.8 20.38 9.1C19.65 9.4 18.99 9.84 18.44 10.4C17.89 10.95 17.45 11.61 17.15 12.34C16.85 13.07 16.7 13.84 16.7 14.63"
                                        stroke="#264EAB" stroke-opacity="1.000000" stroke-width="2.370391"
                                        stroke-linejoin="round" stroke-linecap="round" />
                                </svg>

                                <span>Soft Touch</span>
                            </div>

                            <div class="logo">
                                <svg width="52.000000" height="52.000000" viewBox="0 0 52 52" fill="none"
                                    xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                                    <defs />
                                    <path id="Vector"
                                        d="M43.19 43.58C38.51 48.26 32.15 50.91 25.52 50.93C18.88 50.92 12.52 48.29 7.82 43.6C3.13 38.91 0.5 32.55 0.5 25.92C0.5 22.64 1.14 19.39 2.4 16.36C3.66 13.32 5.5 10.57 7.83 8.25C12.52 3.56 18.89 0.93 25.52 0.93C32.15 0.94 38.5 3.58 43.18 8.27C47.87 12.95 50.5 19.3 50.5 25.92C50.5 32.54 47.87 38.89 43.19 43.58Z"
                                        stroke="#264EAB" stroke-opacity="1.000000" stroke-width="2.000000"
                                        stroke-linejoin="round" />
                                    <path id="Vector" d="M6.01 28.82L45.41 28.82" stroke="#264EAB"
                                        stroke-opacity="1.000000" stroke-width="2.000000" stroke-linejoin="round"
                                        stroke-linecap="round" />
                                    <path id="Vector" d="M25.52 33.35L25.52 45.77" stroke="#264EAB"
                                        stroke-opacity="1.000000" stroke-width="2.000000" stroke-linejoin="round"
                                        stroke-linecap="round" />
                                    <path id="Vector" d="M32.85 33.35L32.85 44.09" stroke="#264EAB"
                                        stroke-opacity="1.000000" stroke-width="2.000000" stroke-linejoin="round"
                                        stroke-linecap="round" />
                                    <path id="Vector" d="M18.1 33.35L18.1 44.09" stroke="#264EAB"
                                        stroke-opacity="1.000000" stroke-width="2.000000" stroke-linejoin="round"
                                        stroke-linecap="round" />
                                    <path id="Vector" d="M40.54 33.35L40.54 40.23" stroke="#264EAB"
                                        stroke-opacity="1.000000" stroke-width="2.000000" stroke-linejoin="round"
                                        stroke-linecap="round" />
                                    <path id="Vector" d="M11.12 33.35L11.12 40.23" stroke="#264EAB"
                                        stroke-opacity="1.000000" stroke-width="2.000000" stroke-linejoin="round"
                                        stroke-linecap="round" />
                                </svg>


                                <span>Matte</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="item">
                <div class="mobile-title">
                    CHOOSE YOUR DRIP BOX
                </div>
                <div class="item-img">
                    <img src="../assets/img/dripBag/desc-full3.png" alt="">
                </div>
                <div class="item-default-text">
                    <div class="title main">
                        CHOOSE YOUR DRIP BOX
                    </div>
                    <div class="properties">
                        <div class="property">
                            <div class="title">
                                SPECS:
                            </div>
                            <div class="text">
                                Box of 10 drips
                            </div>
                        </div>

                        <div class="property">
                            <div class="title">
                                BOX DIMENSIONS:
                            </div>
                            <div class="text">
                                110 mm x 125 mm x 125 mm
                            </div>
                        </div>


                    </div>
                    <div class="logo-property">
                        <div class="title">
                            TOUCH & FEEL EFFECT:
                        </div>
                        <div class="logo-container">
                            <div class="logo">
                                <svg width="52.000000" height="52.000000" viewBox="0 0 52 52" fill="none"
                                    xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                                    <defs />
                                    <path id="Vector"
                                        d="M43.17 43.21C38.48 47.9 32.13 50.53 25.5 50.53C22.21 50.53 18.96 49.89 15.93 48.63C12.89 47.37 10.14 45.53 7.82 43.21C3.13 38.52 0.5 32.16 0.5 25.53C0.5 18.9 3.13 12.54 7.82 7.86C12.51 3.17 18.86 0.53 25.5 0.53C32.13 0.53 38.48 3.17 43.17 7.86C47.86 12.54 50.5 18.9 50.5 25.53C50.5 32.16 47.86 38.52 43.17 43.21Z"
                                        stroke="#264EAB" stroke-opacity="1.000000" stroke-width="2.000000"
                                        stroke-linejoin="round" />
                                    <path id="Vector" d="M5.74 33.69L44.98 33.69" stroke="#264EAB"
                                        stroke-opacity="1.000000" stroke-width="2.000000" stroke-linejoin="round"
                                        stroke-linecap="round" />
                                    <path id="Vector"
                                        d="M17.79 28.5C17.66 27.09 17.03 25.77 16.02 24.77C15.05 23.84 13.8 23.24 12.46 23.09C13.92 23.09 15.33 22.52 16.38 21.5C17.31 20.54 17.85 19.26 17.87 17.93C17.89 19.25 18.4 20.52 19.32 21.48C19.83 21.99 20.44 22.4 21.12 22.68C21.79 22.95 22.51 23.09 23.24 23.09C21.76 23.12 20.36 23.72 19.32 24.77C18.34 25.77 17.8 27.1 17.79 28.5Z"
                                        stroke="#264EAB" stroke-opacity="1.000000" stroke-width="2.000000"
                                        stroke-linejoin="round" />
                                    <path id="Vector"
                                        d="M31.96 22.64C31.62 23.51 31.47 24.43 31.51 25.36C31.45 23.6 30.76 21.92 29.55 20.63C28.86 19.93 28.03 19.37 27.12 18.98C26.21 18.59 25.23 18.39 24.24 18.38C25.24 18.38 26.23 18.19 27.16 17.8C28.09 17.42 28.93 16.85 29.63 16.14C30.89 14.8 31.59 13.03 31.59 11.18C31.55 13.01 32.22 14.78 33.46 16.13C34.82 17.53 36.69 18.34 38.65 18.38C37.7 18.34 36.75 18.49 35.86 18.83C34.97 19.17 34.16 19.68 33.48 20.34C32.81 20.99 32.3 21.78 31.96 22.64Z"
                                        stroke="#264EAB" stroke-opacity="1.000000" stroke-width="2.000000"
                                        stroke-linejoin="round" />
                                    <path id="Vector" d="M15.04 39.71L35.93 39.71" stroke="#264EAB"
                                        stroke-opacity="1.000000" stroke-width="2.000000" stroke-linejoin="round"
                                        stroke-linecap="round" />
                                </svg>
                                <span>Glossy</span>
                            </div>

                            <div class="logo">
                                <svg width="52.000000" height="52.000000" viewBox="0 0 52 52" fill="none"
                                    xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                                    <defs />
                                    <path id="Vector"
                                        d="M43.19 43.58C38.51 48.26 32.15 50.91 25.52 50.93C18.88 50.92 12.52 48.29 7.82 43.6C3.13 38.91 0.5 32.55 0.5 25.92C0.5 22.64 1.14 19.39 2.4 16.36C3.66 13.32 5.5 10.57 7.83 8.25C12.52 3.56 18.89 0.93 25.52 0.93C32.15 0.94 38.5 3.58 43.18 8.27C47.87 12.95 50.5 19.3 50.5 25.92C50.5 32.54 47.87 38.89 43.19 43.58Z"
                                        stroke="#264EAB" stroke-opacity="1.000000" stroke-width="2.000000"
                                        stroke-linejoin="round" />
                                    <path id="Vector" d="M6.01 28.82L45.41 28.82" stroke="#264EAB"
                                        stroke-opacity="1.000000" stroke-width="2.000000" stroke-linejoin="round"
                                        stroke-linecap="round" />
                                    <path id="Vector" d="M25.52 33.35L25.52 45.77" stroke="#264EAB"
                                        stroke-opacity="1.000000" stroke-width="2.000000" stroke-linejoin="round"
                                        stroke-linecap="round" />
                                    <path id="Vector" d="M32.85 33.35L32.85 44.09" stroke="#264EAB"
                                        stroke-opacity="1.000000" stroke-width="2.000000" stroke-linejoin="round"
                                        stroke-linecap="round" />
                                    <path id="Vector" d="M18.1 33.35L18.1 44.09" stroke="#264EAB"
                                        stroke-opacity="1.000000" stroke-width="2.000000" stroke-linejoin="round"
                                        stroke-linecap="round" />
                                    <path id="Vector" d="M40.54 33.35L40.54 40.23" stroke="#264EAB"
                                        stroke-opacity="1.000000" stroke-width="2.000000" stroke-linejoin="round"
                                        stroke-linecap="round" />
                                    <path id="Vector" d="M11.12 33.35L11.12 40.23" stroke="#264EAB"
                                        stroke-opacity="1.000000" stroke-width="2.000000" stroke-linejoin="round"
                                        stroke-linecap="round" />
                                </svg>


                                <span>Matte</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="quality-section">
        <div class="quality-section__container">
            <div class="leftside">
                <img src="../assets/img/dripBag/iso.png" alt="">
                <img src="../assets/img/dripBag/q-check.png" alt="">
            </div>
            <div class="rightside">
                <div class="title">
                    CERTIFIED QUALITY:
                </div>
                <div class="text">
                    Safety production is first! Our packaging production is certified by independent certification
                    bodies.
                </div>
            </div>
        </div>
    </div>
    <div class="how-section">
        <div class="how-section-container">
            <div class="title">how we work</div>
            <div class="how-elements-container">
                <div class="item">
                    <div class="logo">
                        <svg width="72.000000" height="72.000000" viewBox="0 0 72 72" fill="none"
                            xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                            <defs />
                            <path id="Vector 57"
                                d="M53.15 8.51C49.68 8.51 47.95 8.51 46.68 9.29C45.97 9.73 45.37 10.32 44.94 11.03C44.15 12.31 44.15 14.04 44.15 17.51L44.15 26.51L44.15 62.51L35.15 59.51L26.15 62.51L17.15 59.51L8.15 62.51L8.15 15.5C8.15 12.24 8.15 10.61 9.15 9.58C9.17 9.56 9.2 9.53 9.23 9.51C10.25 8.51 11.88 8.51 15.14 8.51L53.15 8.51C56.62 8.51 58.36 8.51 59.63 9.29C60.34 9.73 60.94 10.32 61.37 11.03C62.15 12.31 62.15 14.04 62.15 17.51L62.15 24.77L61.9 26.24L61.89 26.26L60.41 26.51L44.15 26.51"
                                stroke="#264EAB" stroke-opacity="1.000000" stroke-width="3.000000" />
                            <path id="Vector 55"
                                d="M20.15 19.01L32.15 19.01L32.15 22.01L20.15 22.01L20.15 19.01ZM20.17 21.99L20.15 22.01C19.31 22.01 18.65 21.35 18.65 20.51C18.65 19.67 19.31 19.01 20.15 19.01L20.17 19.03L20.17 21.99ZM32.13 19.03L32.15 19.01C32.99 19.01 33.65 19.67 33.65 20.51C33.65 21.35 32.99 22.01 32.15 22.01L32.13 21.99L32.13 19.03Z"
                                fill="#264EAB" fill-opacity="1.000000" fill-rule="evenodd" />
                            <path id="Vector 58"
                                d="M23.15 34.01L20.15 34.01L20.15 31.01L23.15 31.01L23.15 34.01ZM23.13 31.03L23.15 31.01C23.99 31.01 24.65 31.67 24.65 32.51C24.65 33.35 23.99 34.01 23.15 34.01L23.13 33.99L23.13 31.03ZM20.17 33.99L20.15 34.01C19.31 34.01 18.65 33.35 18.65 32.51C18.65 31.67 19.31 31.01 20.15 31.01L20.17 31.03L20.17 33.99Z"
                                fill="#264EAB" fill-opacity="1.000000" fill-rule="evenodd" />
                            <path id="Vector 59"
                                d="M20.15 43.01L29.15 43.01L29.15 46.01L20.15 46.01L20.15 43.01ZM20.17 45.99L20.15 46.01C19.31 46.01 18.65 45.35 18.65 44.51C18.65 43.67 19.31 43.01 20.15 43.01L20.17 43.03L20.17 45.99ZM29.13 43.03L29.15 43.01C29.99 43.01 30.65 43.67 30.65 44.51C30.65 45.35 29.99 46.01 29.15 46.01L29.13 45.99L29.13 43.03Z"
                                fill="#264EAB" fill-opacity="1.000000" fill-rule="evenodd" />
                        </svg>

                    </div>
                    <div class="text">
                        <div class="title">
                            #1 Project Planning
                        </div>
                        <div class="description">
                            During a video call, we discuss your requirements and define a roadmap outlining the steps
                            to move forward.
                        </div>
                    </div>
                </div>

                <div class="item">
                    <div class="logo">
                        <svg width="72.000000" height="72.000000" viewBox="0 0 72 72" fill="none"
                            xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                            <defs />
                            <path id="Vector"
                                d="M38 35.98C37.34 35.98 36.69 35.85 36.09 35.59L12.98 24.88C12.6 24.69 12.29 24.4 12.07 24.04C11.85 23.69 11.73 23.27 11.73 22.85C11.73 22.43 11.85 22.02 12.07 21.66C12.29 21.31 12.6 21.02 12.98 20.83L36.09 10.04C36.69 9.78 37.34 9.64 38 9.64C38.65 9.64 39.3 9.78 39.9 10.04L63.01 20.75C63.39 20.93 63.71 21.22 63.93 21.58C64.15 21.94 64.26 22.35 64.26 22.77C64.26 23.19 64.15 23.61 63.93 23.96C63.71 24.32 63.39 24.61 63.01 24.8L39.9 35.59C39.3 35.85 38.65 35.98 38 35.98Z"
                                stroke="#264EAB" stroke-opacity="1.000000" stroke-width="3.000000"
                                stroke-linejoin="round" />
                            <path id="Vector"
                                d="M64.35 37.41L39.62 48.8C39.09 49.05 38.51 49.17 37.93 49.17C37.35 49.17 36.78 49.05 36.25 48.8L11.64 37.41"
                                stroke="#264EAB" stroke-opacity="1.000000" stroke-width="3.000000"
                                stroke-linejoin="round" stroke-linecap="round" />
                            <path id="Vector"
                                d="M64.35 50.59L39.62 61.98C39.09 62.22 38.51 62.35 37.93 62.35C37.35 62.35 36.78 62.22 36.25 61.98L11.64 50.59"
                                stroke="#264EAB" stroke-opacity="1.000000" stroke-width="3.000000"
                                stroke-linejoin="round" stroke-linecap="round" />
                        </svg>
                    </div>
                    <div class="text">
                        <div class="title">
                            #2 Samples Preparation
                        </div>
                        <div class="description">
                            Once your coffee is delivered, we start testing and filling coffee into drip bags, and then
                            send you a cupping kit. </div>
                    </div>
                </div>

                <div class="item">
                    <div class="logo">
                        <svg width="72.000000" height="72.000000" viewBox="0 0 72 72" fill="none"
                            xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                            <defs />
                            <path id="Vector"
                                d="M47.3 22.04C47.8 22.04 48.28 22.28 48.64 22.71C48.99 23.14 49.19 23.72 49.19 24.33L49.19 44.88C49.19 47.31 48.39 49.63 46.98 51.34C45.57 53.06 43.65 54.02 41.65 54.02L22.8 54.02C20.81 54.02 18.89 53.06 17.47 51.34C16.06 49.63 15.27 47.31 15.27 44.88L15.27 24.33C15.27 23.72 15.47 23.14 15.82 22.71C16.17 22.28 16.65 22.04 17.15 22.04L47.3 22.04Z"
                                stroke="#264EAB" stroke-opacity="1.000000" stroke-width="3.000000"
                                stroke-linejoin="round" />
                            <path id="Vector" d="M11.5 65.44L60.49 65.44" stroke="#264EAB" stroke-opacity="1.000000"
                                stroke-width="3.000000" stroke-linejoin="round" stroke-linecap="round" />
                            <path id="Vector"
                                d="M49.19 26.61L52.96 26.61C54.95 26.61 56.87 27.57 58.29 29.29C59.7 31 60.49 33.32 60.49 35.75C60.49 38.17 59.7 40.49 58.29 42.21C56.87 43.92 54.95 44.88 52.96 44.88L49.19 44.88"
                                stroke="#264EAB" stroke-opacity="1.000000" stroke-width="3.000000"
                                stroke-linejoin="round" stroke-linecap="round" />
                            <path id="Vector" d="M20.92 6.05L20.92 10.62" stroke="#264EAB" stroke-opacity="1.000000"
                                stroke-width="3.000000" stroke-linejoin="round" stroke-linecap="round" />
                            <path id="Vector" d="M39.76 6.05L39.76 10.62" stroke="#264EAB" stroke-opacity="1.000000"
                                stroke-width="3.000000" stroke-linejoin="round" stroke-linecap="round" />
                            <path id="Vector" d="M30.34 6.05L30.34 10.62" stroke="#264EAB" stroke-opacity="1.000000"
                                stroke-width="3.000000" stroke-linejoin="round" stroke-linecap="round" />
                        </svg>
                    </div>
                    <div class="text">
                        <div class="title">
                            #3 Cupping and Sample Confirmation
                        </div>
                        <div class="description">
                            You do a cupping and select your preferred sample, which serves as the quality reference for
                            production.
                        </div>
                    </div>
                </div>

                <div class="item">
                    <div class="logo">
                        <svg width="72.000000" height="72.000000" viewBox="0 0 72 72" fill="none"
                            xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                            <defs>
                                <clipPath id="clip2530_5808">
                                    <rect
                                        id="shipping-transfer-cart--package-box-fulfillment-cart-warehouse-shipping-delivery"
                                        width="72.000000" height="72.000000" transform="translate(-0.634766 -0.497070)"
                                        fill="white" fill-opacity="0" />
                                </clipPath>
                            </defs>
                            <g clip-path="url(#clip2530_5808)">
                                <path id="Vector"
                                    d="M49.61 21.1C51.58 21.1 53.17 22.7 53.17 24.66L53.17 40.69C53.17 42.66 51.58 44.26 49.61 44.26L33.58 44.26C31.61 44.26 30.02 42.66 30.02 40.69L30.02 24.66C30.02 22.7 31.61 21.1 33.58 21.1L49.61 21.1Z"
                                    stroke="#264EAB" stroke-opacity="1.000000" stroke-width="3.000000"
                                    stroke-linejoin="round" />
                                <path id="Vector"
                                    d="M24.67 53.16L51.78 53.16C52.72 53.17 53.61 53.54 54.28 54.2L58.52 58.51"
                                    stroke="#264EAB" stroke-opacity="1.000000" stroke-width="3.000000"
                                    stroke-linejoin="round" stroke-linecap="round" />
                                <path id="Vector"
                                    d="M12.2 12.19L15.77 12.19C16.71 12.19 17.62 12.57 18.28 13.24C18.95 13.9 19.33 14.81 19.33 15.76L19.33 46.04"
                                    stroke="#264EAB" stroke-opacity="1.000000" stroke-width="3.000000"
                                    stroke-linejoin="round" stroke-linecap="round" />
                                <path id="Vector"
                                    d="M24.67 53.16C24.56 54.77 23.82 56.26 22.63 57.33C21.43 58.4 19.87 58.97 18.27 58.91C16.66 58.86 15.14 58.18 14.03 57.02C12.91 55.87 12.29 54.32 12.29 52.72C12.29 51.11 12.91 49.57 14.03 48.41C15.14 47.26 16.66 46.58 18.27 46.52C19.87 46.47 21.43 47.03 22.63 48.11C23.82 49.18 24.56 50.67 24.67 52.27C24.69 52.57 24.69 52.87 24.67 53.16Z"
                                    stroke="#264EAB" stroke-opacity="1.000000" stroke-width="3.000000"
                                    stroke-linejoin="round" />
                                <path id="Vector" d="M40.7 35.35L44.27 35.35" stroke="#264EAB" stroke-opacity="1.000000"
                                    stroke-width="3.000000" stroke-linejoin="round" stroke-linecap="round" />
                            </g>
                        </svg>
                    </div>
                    <div class="text">
                        <div class="title">
                            #4 Kick off & Regular Production
                        </div>
                        <div class="description">
                            We agree on a delivery schedule tailored to your timeline and run regular production,
                            ensuring just-in-time deliveries to keep your drip coffee in stock.
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <catCta />
</template>
<script>
import blueBtn from '@/components/blueBtn.vue';
import catCta from '@/components/dripCat.vue';
import { Swiper, SwiperSlide } from "swiper/vue";
import TransparentButton from '../components/TransparentButton.vue'
// eslint-disable-next-line
import { Navigation, Pagination } from 'swiper/modules';

import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";



export default {
    data() {
        return {
            swiperOptions: { // Опции адаптивного слайдера
                slidesPerView: 3,
                spaceBetween: 50,
                pagination: {
                    clickable: true,
                },
                loop: true,
                breakpoints: {
                    1008: {
                        slidesPerView: 3,
                    },

                    700: {
                        slidesPerView: 2,
                    }
                },
            },
        }
    },

    setup() {
        return {
            modules: [Pagination, Navigation],
        };
    },

    components: {
        blueBtn,
        catCta,
        Swiper,
        SwiperSlide,
        TransparentButton
    }
}
</script>
<style scoped>
.arrow-icon-mobile {
    display: none;
}

body {
    overflow-x: hidden;
}

body {
    margin: 0;
    overflow-x: hidden;
  }
  
  html {
    overflow-x: hidden;
  }

.learn-more-container {
    max-width: 1240px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 85px;
}

.learn-more-text-title {
    display: flex;
    color: #264EAB;
    font-size: 80px;
    font-family: 'RobotoMono-M', sans-serif;
    align-items: flex-start;
    justify-content: flex-end;
    margin-bottom: 89px;
    margin-top: 72px;
}

.product-full-description__cotainer .item .mobile-title {
    color: rgb(38, 78, 171);
    font-family: 'RobotoMono-R', sans-serif;
    font-size: 25px;
    line-height: 33px;
    display: none;
    margin-bottom: 25px;
    margin: 0 auto 25px auto;
}



.learn-more-text-subtitle {
    color: #264EAB;
    font-size: 46px;
    font-family: 'RobotoMono-M', sans-serif;
    margin-bottom: 47px;
}

.learn-more-text-description {
    font-family: 'RobotoMono-L', sans-serif;
    color: #264EAB;
    font-size: 32px;
}

.learn-more-text-title span {
    margin-top: -50px;
    margin-left: 44px;
}

.learn-more-text {
    max-width: 720px;
}

.learn-more-img {
    margin-top: -50px;
}


.delivery-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: 1240px;
    margin: 0 auto;
    margin-top: 50px;
    position: relative
}

.delivery-text {
    display: flex;
    flex-direction: column;
    max-width: 903px;
}

.delivery-title {
    color: #264EAB;
    font-family: 'RobotoMono-L', sans-serif;
    font-size: 80px;
    margin-bottom: 10px;
}

.delivery-title span {
    font-family: 'RobotoMono-R', sans-serif;
}

.newArrow {
    display: none;
}

.delivery-img {
    margin-right: -200px;
}

.delivery-subtitle {
    font-size: 46px;
    color: #264EAB;
    font-family: 'RobotoMono-L', sans-serif;
    max-width: 700px;
    margin-bottom: 80px;
}

.buttons {
    margin-bottom: 65px;
}

.product-description__mobile {
    display: none;
}

.description-container {
    display: flex;
    max-width: 1240px;
    margin: 0 auto;
    align-items: center;
    margin-bottom: 100px;
}

.description-container img {
    margin-left: -100px;
}


.description-text {
    color: rgb(38, 78, 171);
    font-family: 'RobotoMono-L', sans-serif;
    font-size: 46px;
    font-weight: 300;
    line-height: 61px;
    letter-spacing: 0%;
    text-align: left;
    max-width: 885px;
    margin-left: 51px;
}

.gray-section {
    padding: 60px 0;
    background: #F5F5F5;
}

.gray-section__container {
    max-width: 1240px;
    margin: 0 auto;
}

.gray-section__title {
    color: rgb(38, 78, 171);
    font-family: 'RobotoMono-R', sans-serif;
    font-size: 46px;
    font-weight: 400;
    line-height: 61px;
    letter-spacing: 0%;
    text-align: left;
    text-transform: uppercase;
    margin-bottom: 50px;
}

.gray-section__text {
    color: rgb(38, 78, 171);
    font-family: 'RobotoMono-L', sans-serif;
    font-size: 32px;
    font-weight: 300;
    line-height: 130%;
    letter-spacing: 0%;
    text-align: left;
}

.product-description__container {
    max-width: 1240px;
    margin: 0 auto;
    padding-top: 115px;
}

.product-description__container .pd-item-box {
    display: flex;
    justify-content: space-between;
    margin-bottom: 100px;
}

.product-description__container .pd-item-box .item {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 330px;
    text-align: center
}

.product-description__container .pd-item-box .item img {
    height: 317px;
}

.product-description__container .pd-item-box .item .title {
    color: rgb(38, 78, 171);
    font-family: 'RobotoMono-R';
    font-size: 36px;
    font-weight: 400;
    line-height: 47px;
    letter-spacing: 0%;
    text-align: left;
    margin-top: 30px;
    margin-bottom: 15px;
}


.product-description__container .pd-item-box .item .description {
    color: rgb(38, 78, 171);
    font-family: 'RobotoMono-L', sans-serif;
    font-size: 24px;
    line-height: 32px;
    text-align: center;
}

.product-full-description__cotainer {
    max-width: 1240px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
}

.product-full-description__cotainer .item {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    margin-bottom: 50px;

}

.product-full-description__cotainer .item .item-img {
    margin-right: 60px;
}

.product-full-description__cotainer .item .title {
    color: rgb(38, 78, 171);
    font-family: 'RobotoMono-R', sans-serif;
    font-size: 46px;
    line-height: 61px;
    text-align: left;
    text-transform: uppercase;
}

.product-full-description__cotainer .item .properties {
    display: flex;
    flex-wrap: wrap;
    margin-top: 30px;
}

.product-full-description__cotainer .item .properties .property {
    width: 412px;
    margin-bottom: 30px;
}

.product-full-description__cotainer .item .properties .property .title {
    color: rgb(38, 78, 171);
    font-family: 'RobotoMono-R', sans-serif;
    font-size: 36px;
    line-height: 47px;
    text-align: left;
}

.product-full-description__cotainer .item .properties .property .text {
    color: rgb(38, 78, 171);
    font-family: 'RobotoMono-L', sans-serif;
    font-size: 24px;
    line-height: 32px;
}

.product-full-description__cotainer .item .logo-property .title {
    color: rgb(38, 78, 171);
    font-family: 'RobotoMono-R', sans-serif;
    font-size: 36px;
    line-height: 47px;
    text-align: left;
    margin-bottom: 15px;
}

.product-full-description__cotainer .item .logo-property .logo-container {
    display: flex;
    align-items: flex-end
}

.product-full-description__cotainer .item .logo-property .logo-container .logo span {
    color: rgb(38, 78, 171);
    font-family: 'RobotoMono-L', sans-serif;
    font-size: 24px;
    line-height: 32px;
    text-align: left;
    margin-left: 15px;
}


.product-full-description__cotainer .item .logo-property .logo-container .logo svg {
    margin-bottom: -10px;
}


.product-full-description__cotainer .item .logo-property .logo-container .logo {
    margin-right: 40px;
}

.quality-section {
    background: rgb(247, 246, 241);
    margin-top: 100px;
    padding: 75px 0
}

.quality-section .quality-section__container {
    max-width: 910px;
    margin: 0 auto;
    display: flex;
    align-items: center;
}

.quality-section .quality-section__container .leftside {
    display: flex;
    flex-direction: column;
    max-width: 130px;
    align-items: center;
    margin-right: 30px;
}

.quality-section .quality-section__container .leftside img {
    width: 100%;
}

.quality-section .quality-section__container .leftside img:first-child {
    width: 90px;
    margin-bottom: 33px;
}

.quality-section .quality-section__container .rightside .title {
    color: rgb(38, 78, 171);
    font-family: 'RobotoMono-R', sans-serif;
    font-size: 46px;
    line-height: 61px;
    text-align: left;
    margin-bottom: 20px;
}

.quality-section .quality-section__container .rightside .text {
    color: rgb(38, 78, 171);
    font-family: 'RobotoMono-L', sans-serif;
    font-size: 32px;
    line-height: 42px;
    text-align: left;
}


.how-section-container {
    max-width: 1240px;
    margin: 0 auto;
    padding: 100px 0;
}

.how-section-container .title {
    color: rgb(38, 78, 171);
    font-family: 'RobotoMono-R', sans-serif;
    font-size: 46px;
    line-height: 61px;
    text-transform: uppercase;
    text-align: center;
    margin-bottom: 50px;
}

.how-section-container .how-elements-container {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-wrap: wrap;
}

.how-section-container .how-elements-container .item {
    width: 573px;
    display: flex;
    align-items: flex-start;
    margin-bottom: 50px;
}

.how-section-container .how-elements-container .item .logo {
    margin-right: 10px;
}

.how-section-container .how-elements-container .item .text .title {
    color: rgb(38, 78, 171);
    font-family: 'RobotoMono-L', sans-serif;
    font-size: 36px;
    line-height: 47px;
    text-align: left;
    margin-bottom: 15px;
    text-transform: none
}

.how-section-container .how-elements-container .item .text .description {
    color: rgb(38, 78, 171);
    font-family: 'RobotoMono-L', sans-serif;
    font-size: 24px;
    line-height: 32px;
}


@media screen and (max-width: 1260px) {

    .delivery-container,
    .description-container,
    .gray-section__container,
    .product-description__container,
    .quality-section__container,
    .how-section-container,
    .product-full-description__cotainer {
        padding-left: 20px;
        padding-right: 20px;
    }

    .product-full-description__cotainer .item .properties .property {
        width: 370px
    }

    .product-full-description__cotainer .item .properties .property .title {
        font-size: 26px;
    }

    .product-full-description__cotainer .item {
        justify-content: unset;
    }
}

@media screen and (max-width: 1200px) {
    .product-description__container .pd-item-box .item img {
        height: 230px;
    }

    .product-description__container .pd-item-box .item .description {
        font-size: 20px;
        line-height: 24px
    }

    .product-description__container .pd-item-box .item .title {
        font-size: 30px
    }

    .product-full-description__cotainer .item .item-img {
        height: 250px;
    }

    .product-full-description__cotainer .item .item-img img {
        height: 100%;
    }

    .how-section-container .how-elements-container .item .text .title {
        font-size: 30px;
    }

    .how-section-container .how-elements-container .item .text .description {
        font-size: 20px;
    }

    .how-section-container .how-elements-container .item {
        width: 510px
    }

    .item-default-text {
        width: 100%;
    }

    .description-text {
        font-size: 34px;
        line-height: 40px;
    }
}


@media screen and (max-width: 1050px) {
    .how-section-container .how-elements-container {
        flex-direction: column
    }

    .how-section-container .how-elements-container .item {
        width: 100%;
    }

    .product-full-description__cotainer .item .title {
        font-size: 36px;
    }

    .delivery-img {
        height: 400px;
        margin-right: -100px
    }

    .delivery-img img {
        height: 100%;
    }

    .delivery-title {
        font-size: 60px;
    }

    .delivery-subtitle {
        font-size: 36px;
        max-width: 600px;
    }

    .product-full-description__cotainer .item .logo-property .logo-container .logo span {
        font-size: 18px
    }

    .product-full-description__cotainer .item .logo-property .title {
        font-size: 30px
    }
}

@media screen and (max-width: 900px) {
    .product-description__container {
        display: none;
    }


    .product-description__mobile {
        display: flex;
        margin-top: 50px;
        margin-bottom: 100px
    }

    .product-description__mobile {
        display: flex;
    }

    .swiper {
        padding-top: 0 !important;
    }

    .product-description__mobile .item {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .product-description__mobile .item img {
        margin-bottom: 15px
    }

    .product-description__mobile .item .title {
        color: rgb(38, 78, 171);
        font-family: 'RobotoMono-R', sans-serif;
        font-size: 19px;
        text-transform: uppercase;
        margin-bottom: 5px;
    }

    .product-description__mobile .item .description {
        color: rgb(38, 78, 171);
        font-family: 'RobotoMono-L', sans-serif;
        font-size: 16px;
        line-height: 21px;
    }

}

@media screen and (max-width: 850px) {
    .delivery-title {
        font-size: 46px;
    }

    .delivery-subtitle {
        font-size: 30px
    }

    .delivery-container .buttons {
        flex-direction: column;
        display: flex;
        max-width: 240px
    }

    .buttons a {
        padding: 0;
        width: 241px;
        height: 54px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .buttons a:first-child {
      margin-bottom: 10px
    }

    .gray-section__title {
        font-size: 38px;
        line-height: 42px;
        margin-bottom: 25px
    }

    .gray-section__text {
        font-size:26px;
    }

    .quality-section .quality-section__container .rightside .title {
        font-size: 40px;
    }

    .quality-section .quality-section__container .rightside .text {
        font-size: 26px;
        line-height: 30px;
    }
}



@media screen and (max-width: 800px) {
    .product-full-description__cotainer .item .title.main {
        display: none
    }

    .product-full-description__cotainer .item {
        flex-direction: column;
    }

    .product-full-description__cotainer .item .item-img {
        margin-right: 0;
        height: unset;
        margin: 0 auto
    }

    .product-full-description__cotainer .item .mobile-title {
        display: flex;
    }
}


@media screen and (max-width: 730px) {
    .delivery-title {
        font-size: 40px;
        
    }

    .delivery-subtitle {
        font-size: 26px;
        margin-bottom: 20px;
    }

    .description-text {
        font-size: 27px;

    }

    .description-container {
        margin-top: 50px;
        margin-bottom: 50px;
    }

    .description-container img {
        height: 357px;
        margin-left: -30px;
    }

    .how-section-container .title {
        font-size:38px;
        margin-bottom: 20px
    }

    .how-section-container .how-elements-container .item .text .title {
        font-size: 26px;
        margin-bottom: 10px
    }

    .how-section-container .how-elements-container .item .text .description {
        font-size: 18px;
        line-height: 22px;
    }

    .quality-section {
        margin-top: 0;
    }

    .how-section-container {
        padding: 50px 20px;
    }

    .how-section-container .how-elements-container .item:last-child {
        margin-bottom: 0;
    }



}


@media screen and (max-width: 660px) {
    .delivery-text {
        margin-bottom: 360px
    }

    .delivery-img {
        right: -55px;
        position: absolute;
        bottom: 0;
        margin-right: 0
    }

    .delivery-subtitle {
        font-family: 'RobotoMono-R', sans-serif
    }

    .description-text {
        font-size: 22px;
        line-height: 26px;
    }

    .description-container img {
        height: 272.29px;
    }

    .gray-section {
        padding: 25px 0
    }

    .gray-section__title {
        font-size: 32px;
        line-height: 36px;
    }

    .gray-section__text {
        font-size: 20px;
    }

    .product-description__mobile {
        text-align: center;
        max-width: 80%;
        margin: 50px auto 100px auto
    }

    .product-full-description__cotainer .item .item-img {
        height: 352px;
    }

    .product-full-description__cotainer .item .item-img img {
        height: 100%;
    }

    .product-full-description__cotainer .item .properties .property .title {
        font-size: 24px;
        line-height: 28px;
    }

    .product-full-description__cotainer .item .properties .property .text {
        font-size: 20px
    }

    .product-full-description__cotainer .item .properties .property {
        width: 345px;
    }

    .product-full-description__cotainer .item .logo-property .title {
        font-size: 24px;
        margin-bottom: 0;
    }

    .product-full-description__cotainer .item .logo-property .logo-container .logo svg {
        width: 40px !important; 
    }

    .product-full-description__cotainer .item .logo-property .logo-container .logo {
        margin-right: 20px;
    }

    .quality-section .quality-section__container {
        flex-direction: column;
        align-items: flex-start;
        
    }

    .quality-section .quality-section__container .rightside .title {
        font-size: 32px;
    }

    .quality-section .quality-section__container .rightside .text {
        font-size: 21px;
    }

    .quality-section .quality-section__container .rightside {
        order: 1;
    }
    .quality-section .quality-section__container .leftside {
        order: 2;
        display: flex;
        align-items: center;
        max-width: unset;
        flex-direction: row;
        margin-right: 0;
        margin-top: 20px;
    }

    .quality-section .quality-section__container .leftside img:first-child {
        margin-right: 30px;
        margin-bottom: 0;
        height: 65px;
        width: 65px;
    }

    .quality-section .quality-section__container .leftside img:last-child {
        width: 128.85px;
        height: 51.98px;
    }

    .quality-section {
        padding: 30px 0;
    }

}


@media screen and (max-width: 500px) {
    .delivery-title {
        font-size: 34px;
    }

    .delivery-subtitle {
        font-size: 21px;
        max-width: 350px;
    }

    .description-text {
        margin-left: 5px;
        font-size: 19px;
    }

    .description-container img {
        margin-left: -60px;
    }

    .gray-section__title {
        font-size: 19px;
    }

    .gray-section__text {
        font-size: 16px;
    }

    .product-description__mobile .item img {
        height: 222px;
    }

    .product-description__mobile > .swiper-horizontal > .swiper-pagination-bullets,  .product-description__mobile >>> .swiper-pagination-bullets.swiper-pagination-horizontal {
        left: calc(50% - 150px) !important;
        margin-left: 0 !important;
    }

    .product-description__mobile .item .description {
        font-size: 15px;
    }

    .product-full-description__cotainer .item .item-img {
        height: 272px;
    }

    .product-full-description__cotainer .item .properties .property .title, .product-full-description__cotainer .item .logo-property .title {
        font-size: 19px;
    }

    .product-full-description__cotainer .item .logo-property .logo-container {
        flex-wrap: wrap;
    }

    .product-full-description__cotainer .item .properties .property .text {
        font-size: 16px;
    }

    .product-full-description__cotainer .item .properties .property {
        margin-bottom: 20px;
    }
    .product-full-description__cotainer .item .logo-property .logo-container .logo {
        margin-bottom: 20px;
    }

    .product-full-description__cotainer .item .logo-property .logo-container .logo span {
        font-size: 16px;
    }

    .product-full-description__cotainer .item .logo-property .logo-container .logo svg {
        width: 35px !important;
        margin-top: -15px;
        height: 35px;
    }

    .quality-section .quality-section__container .rightside .title {
        font-size: 19px;
line-height: 100%;
    }

    .quality-section .quality-section__container .rightside .text {
        font-size: 16px;
line-height: 21px;
    }

    .quality-section .quality-section__container .leftside img:first-child {
        width: 45.41px;
height: 45.53px;
    }

    .quality-section .quality-section__container .leftside img:last-child {
        width: 68.85px;
        height: 31.98px;
    }


    .how-section-container .title {
        font-size: 25px;
    }

    .how-section-container .how-elements-container .item .text .title {
        font-size: 19px;
line-height: 25px;
    }

    .how-section-container .how-elements-container .item .text .description {
        font-size: 16px;
line-height: 21px;
    }

    .how-section-container .how-elements-container .item .logo svg {
        width: 32px;
        height: 32px;
    }
}
</style>
