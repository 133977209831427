<template>
  <headerComponent/>
  <router-view></router-view>
  <footerComponent/>
</template>

<script>
import headerComponent from './components/headerComponent.vue'
import footerComponent from './components/footerComponent.vue';

export default {
  name: 'App',
  components: {
    headerComponent,
    footerComponent
  }
}
</script>

<style>
@font-face {
  font-family: 'RobotoMono-R';
  src: url(../src/assets/fonts/RobotoMono-Regular.ttf);
  font-display: swap;
}
@font-face {
  font-family: 'RobotoMono-L';
  src: url(../src/assets/fonts/RobotoMono-Light.ttf);
  font-display: swap;
}
@font-face {
  font-family: 'RobotoMono-M';
  src: url(../src/assets/fonts/RobotoMono-Medium.ttf);
  font-display: swap;
}
@font-face {
  font-family: 'RobotoMono-B';
  src: url(../src/assets/fonts/RobotoMono-Bold.ttf);
  font-display: swap;
}

#app {
  margin: 0;
}
body {
  margin: 0;
  font-family: 'RobotoMono-L', sans-serif;
  overflow-x: hidden;
}

html {
  overflow-x: hidden;
}



.form-input {
  width: 100%;
  height: 40px;
  padding: 5px 15px;
}

.form-label {
  position: absolute;
  pointer-events: none;
  left: 40px;
  right: 15px;
  top: 40px;
  white-space: nowrap;
  overflow: hidden;
  line-height: 24px;
  transition: .3s;
  color: var(--main-blue, #264EAB);
    font-family: 'RobotoMono-L', sans-serif;
    font-size: 24px;
}

.form-input:focus~.form-label,
.form-input:not(:focus):valid~.form-label {
  top: 8px;
  font-size: 24px;
  width: unset;
  padding: 0 10px; 
  line-height: 24px;
  z-index: 22;
  width: fit-content;
}

.form-input:focus~.form-label::before, .form-label.red::before  {
  content: " ";
  width: 101%;
  background: #fff;
  height: calc(24px - 34%);
  position: absolute;
  top: -3px;
  left: 0;
  z-index: -1;
  transition: 0.4s;
}

.form-label.red {
  color: red;
}

.field-in  .form-input:focus {
  border: 1px solid #264EAB;
}


.field-in {
  display: inline-block;
  vertical-align: top;
  position: relative;
}


@media screen and (max-width: 804px) {
  .field-in .form-label {
    font-size: 18px;
  }

  .form-input:focus~.form-label,
.form-input:not(:focus):valid~.form-label {
  font-size: 18px;
  line-height: 18px;
}

  
}

@media screen and (max-width: 630px) {
  .field-in .form-label{
    top: 30px;
  }

  .form-input:focus~.form-label, .form-input:not(:focus):valid~.form-label {
    top: -3px;
  }
}

@media screen and (min-width: 1440px) {
  body {
    transform: scale(0.8);
    transform-origin: top left; /* Устанавливаем точку применения масштаба в верхний левый угол */
    width: 125%; /* Увеличиваем ширину области просмотра, чтобы избежать горизонтальной прокрутки */
    overflow: hidden; 
    height: fit-content;
  }
}

html {
  height: 100% !important;
}


</style>
