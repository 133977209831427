<template>
    <div v-if="showPopup3" class="popup" @click="closePopup">
        <div class="popup-content">
            <div class="popup-title">
                Food Packaging Regulations
            </div>
            <div class="popup-text">
                <span class="popup-title">
                    Regulatory Framework
                </span>
                <ul>
                    <li><span class="bold"> EU:</span>
                       <a href="https://eur-lex.europa.eu/eli/dir/1994/62/oj">Packaging and Packaging Waste Directive (94/62/EC)</a>, emphasizing recycling and waste management.
                    </li>
                    <li><span class="bold"> China:</span>
                        <a href="http://www.npc.gov.cn/zgrdw/englishnpc/Law/2007-12/12/content_1383723.htm#:~:text=Article%2017%20Units%20and%20individuals,authorization%2C%20or%20discard%20or%20litter"
                            target="_blank">"Solid Waste Environmental Pollution Prevention Law"</a>
                    </li>
                </ul>
            </div>
            <div class="popup-text">
                <span class="popup-title">
                    Plastics Strategy:
                </span>
                <ul>
                    <li><span class="bold"> EU:</span> 
                       <a href="https://commission.europa.eu/strategy-and-policy/priorities-2019-2024/european-green-deal_en">European Green Deal</a>, targets making all plastic packaging recyclable by 2030.

                    </li>
                    <li><span class="bold"> China:</span>China's approach to plastics and recycling is part of broader waste management efforts, with an emphasis on recycling education and waste sorting.
                    </li>
                </ul>
            </div>
            <div class="popup-text">
                <span class="popup-title">
                    Food Contact Materials:
                </span>
                <ul>
                    <li><span class="bold"> EU:</span>
                        <a href="https://op.europa.eu/en/web/eu-law-in-force/bibliographic-details/-/elif-publication/08a6f2ca-ddae-4630-801b-debd699a5af2">Regulation (EC) No. 1935/2004</a> and specific measures like <a href="https://op.europa.eu/en/web/eu-law-in-force/bibliographic-details/-/elif-publication/84665a0a-f9e5-4eae-aac5-af4fd4c8ac94">Regulation (EU) No. 10/2011 for plastics.</a>                   
                    </li>
                    <li><span class="bold"> China:</span>
                        <a href="http://www.npc.gov.cn/zgrdw/englishnpc/Law/2011-02/15/content_1620635.htm">Food Safety Law</a> and associated regulations, with National Standards (GB standards) for materials in food packaging.
                    </li>
                </ul>
            </div>

            <div class="popup-text">
                <span class="popup-title">
                    Recycled Plastics:
                </span>
                <ul>
                    <li><span class="bold"> EU:</span>
                        Imposes strict criteria for using recycled plastics in food contact applications. Not all recycled plastics are permitted, and safety evaluations are mandatory.
                    </li>
                    <li><span class="bold"> China:</span>
                        Specific standards governing the use of recycled materials in food contact applications. These standards may differ from EU requirements in terms of approved materials, conditions, and usage levels.
                    </li>
                </ul>
            </div>
           
            <div class="popup-close" @click="closePopup">
                <svg class="close" width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M9.165 9.13827C9.36026 8.94301 9.67685 8.94301 9.87211 9.13827L12.5186 11.7847L15.165 9.13827C15.3603 8.94301 15.6768 8.94301 15.8721 9.13827C16.0674 9.33353 16.0674 9.65011 15.8721 9.84537L13.2257 12.4918L15.8721 15.1383C16.0674 15.3335 16.0674 15.6501 15.8721 15.8454C15.6768 16.0406 15.3603 16.0406 15.165 15.8454L12.5186 13.1989L9.87211 15.8454C9.67685 16.0406 9.36026 16.0406 9.165 15.8454C8.96974 15.6501 8.96974 15.3335 9.165 15.1383L11.8114 12.4918L9.165 9.84537C8.96974 9.65011 8.96974 9.33353 9.165 9.13827Z" fill="#264EAB"/>
                    </svg>
                    
            </div>
            <div class="close-btn" @click="closePopup">
                close
            </div>
        </div>
    </div>
</template>
  
<script>
export default {
    props: {
        showPopup3: Boolean
    },
    methods: {
        closePopup() {
            if (event.target.classList.contains('popup') || event.target.classList.contains('close') || event.target.classList.contains('close-btn')) {
                this.$emit('close-popup');
            }
        }
    }
}
</script>
    .
<style scoped>
body {
    position: relative;
}

.popup {
    background: rgba(0, 0, 0, 0.2);
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 9999;
}

.popup-content {
    background: var(--bg-1, #FFF);
    box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.15);
    padding: 60px 40px;
    position: relative;
    max-width: 1000px;
    height: fit-content;
    overflow-y: scroll;

}

.popup-title {
    color: var(--button-blue, #264EAB);
    font-family: 'RobotoMono-B', sans-serif;
    font-size: 16px;
    line-height: normal;
    margin-bottom: 44px;
}

ul li {
    color: var(--button-blue, #264EAB);
    font-family: 'RobotoMono-R', sans-serif;
    font-size: 16px;
    line-height: normal;
    margin-bottom: 10px;
}

.close-btn {
    color: #C3C3C3;
font-family: 'RobotoMono-R', sans-serif;
font-size: 16px;
text-decoration-line: underline;
margin-top: 25px;
display: flex;
    justify-content: center;
    display: none;
}

li a {
    color: var(--button-blue-hover, #3771F8);
    font-size: 16px;
    text-decoration-line: underline;
}

li .bold {
    margin-right: 5px;
    font-family: 'RobotoMono-B', sans-serif;
}

.text {
    padding-left: 20px;
    color: var(--button-blue, #264EAB);
font-family: 'RobotoMono-R', sans-serif;
font-size: 16px;
line-height: normal;
margin-top: 18px;
margin-bottom: 18px;
}

.popup-close {
    position: absolute;
    top: 40px;
    right: 40px;
}

.popup-close:hover {
    cursor: pointer;
}



@media screen and (max-width: 1300px) {
    .popup-content {
        padding-left: 20px;
        padding-right: 20px;
    }
}

@media screen and (max-width: 680px) {
    .popup-content {
        padding-top: 80px;
    }

    .popup-title:first-child {
        max-width: 250px
    }

    .popup-content {
        padding: 80px 40px 60px 40px;
    }

    ul {
        padding-left: 50px;
    }

    .text {
        padding-left: 30px;
    }
}


@media screen and (max-width: 650px) {
    .close-btn {
        display: flex;
    }
}

@media screen and (max-width: 650px) {
    .close-btn {
        display: flex;
    }
}



@media screen and (max-width: 650px) {

    .popup-content {
        width: 100% !important;
        height: 100%;

    }
}

.popup-close:hover {
    cursor:pointer
}
</style>
  