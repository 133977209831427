/* eslint-disable no-unused-vars */
import { createRouter, createWebHistory } from "vue-router";
import mainPage from './layouts/mainPage.vue'
import coffeeBag from './layouts/coffeeBag.vue'
import aboutPage from './layouts/aboutPage'
import dripBag from './layouts/dripBag.vue'
import contactUs from './layouts/contactUs.vue'
import orderPage from './layouts/orderPage.vue'
import getQuote from './layouts/getQuote.vue'
import callPage from './layouts/callPage.vue'
import claimReport from './layouts/claimReport.vue'
import drip_coffee_presentation from './layouts/drip_coffee_presentation.vue'

export default createRouter({
  history: createWebHistory(),
  routes: [
    { path: '/', component: mainPage },
    { path: '/coffeeBag', component: coffeeBag },
    { path: '/about', component: aboutPage },
    { path: '/dripBag', component: dripBag },
    { path: '/contact', component: contactUs },
    { path: '/order', component: orderPage },
    { path: '/quote', component: getQuote },
    { path: '/call', component: callPage },
    { path: '/claim-report', component: claimReport },
    { path: '/drip_coffee_presentation', component: drip_coffee_presentation }

  ],

  scrollBehavior () {
    return {left: 0, top: 0};
  }
});