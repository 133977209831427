import { createApp } from 'vue'
import App from './App.vue'
import router from './router.js'
import PrimeVue from 'primevue/config';
import Dropdown from 'primevue/dropdown';
import 'maz-ui/css/main.css'
import MazBtn from 'maz-ui/components/MazBtn'
import MazInput from 'maz-ui/components/MazInput'
import VueLazyload from 'vue-lazyload';

import MazPhoneNumberInput from 'maz-ui/components/MazPhoneNumberInput'




const app = createApp(App)



app.use(router)
app.use(PrimeVue);
app.use(VueLazyload);
app.component('DropdownComp', Dropdown);
// Make BootstrapVue available throughout your project
app.component('MazBtn', MazBtn)
app.component('MazInput', MazInput)
app.component('MazPhoneNumberInput', MazPhoneNumberInput)


app.mount('#app')